// @flow
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  filterPosters,
  getPostersFromCategory,
  getPostersFromEvent
} from "../../redux/modules/postersModule";
import { Poster } from "../../model/Poster";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { getEvent } from "../../redux/modules/eventsModule";
import { AttachmentPreview } from "../../components/AttachmentPreview/AttachmentPreview";
import { savePosterAction } from "../../redux/modules/posterActionsModule";
import styles from './postersSection.module.css';
import Search from "@material-ui/icons/Search";

type Props = {
  match: any,
  event: Event,
  getEvent: (eventId: number) => void,
  postersFromEvent: Poster[],
  postersFromCategory: Poster[],
  filteredPosters: Poster[],
  getPostersFromCategory: (categoryId: number) => void,
  getPostersFromEvent: (eventId: number) => void,
  filterPosters: (query: string) => void,
  savePosterAction: (poster: Poster, event: Event) => void
};
const PostersSection = (props: Props) => {
  const [filterQuery, setFilterQuery] = useState('');

  useEffect(() => {
    props.getEvent(props.match.params.id);
    props.filterPosters('', []);
    if (props.match.params.categoryId) {
      props.getPostersFromCategory(props.match.params.categoryId);
    } else {
      props.getPostersFromEvent(props.match.params.id);
    }
  }, []);

  let savedPosters = props.match.params.categoryId
    ? props.postersFromCategory
    : props.postersFromEvent;

  let posters = savedPosters;

  if (!posters || !props.event)
    return (
      <Grid
        container
        direction={"row"}
        justify={"center"}
        style={{ margin: 20 }}
      >
        <CircularProgress />
      </Grid>
    );

  if (props.filteredPosters && filterQuery.length > 0)
    posters = props.filteredPosters;

  posters.sort((a: Poster, b: Poster) => {
      if(a.order && b.order) {
          return a.order - b.order;
      } else {
          return a.name < b.name ? 1 : a.name > b.name ? -1 : 0;
      }
  });

  const navBar = (
    <Grid
      container
      direction={"row"}
      justify={"center"}
      alignContent={"center"}
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: 50,
        background: "black"
      }}
    >
      {props.match.params.categoryId ? (
        <div
          style={{
            background: "white",
            borderRadius: 10,
            cursor: "pointer",
            padding: 10,
            marginTop: 10,
            marginBottom: 10,
            marginRight: 10
          }}
          onClick={() => {
            props.history.goBack();
          }}
        >
          Principal
        </div>
      ) : null}
      {props.event ? (
        <div
          style={{
            background: "white",
            borderRadius: 10,
            cursor: "pointer",
            padding: 10,
            marginTop: 10,
            marginBottom: 10,
            marginRight: 10
          }}
          onClick={() => {
            props.history.push(`/public/event/${props.event.id}/presentation`);
          }}
        >
          Presentación
        </div>
      ) : null}
    </Grid>
  );

  const searchBar = (
    <Grid
      container
      direction={"row"}
      justify={"center"}
      alignContent={"center"}
      style={{
        height: 50,
          background: "black",
      }}
    >
        <Search style={{ color: "white", marginTop: 5}} />
      <TextField
        variant={"standard"}
        style={{
            background: "white",

          paddingLeft: 20,
          paddingRight: 20,
          borderRadius: 10
        }}
        onChange={event => {
          setFilterQuery(event.target.value);
          props.filterPosters(filterQuery, savedPosters);
        }}
      />
    </Grid>
  );

  return (
    <Grid container direction={"column"}>
      {props.match.params.categoryId ? (
        props.event.eventBannerUrl ? (
          <img
            src={props.event.eventBannerUrl}
            style={{ width: "100%", height: 100, objectFit: "cover" }}
          />
        ) : (
          <Grid
            direction={"row"}
            justify={"center"}
            style={{
              background: props.event.titleColor || "grey",
              padding: 40,
              fontSize: 40,
              color: "white",
              textAlign: "center"
            }}
          >
            Banner de {props.event.name}
          </Grid>
        )
      ) : null}
        {searchBar}
      <div
        style={{
          height: "calc(100vh - 70px - 120px)",
          overflowY: "scroll"
        }}
        className={styles.noScrollBar}
      >
        {posters.map((poster: Poster) => (
          <Grid
            container
            direction={"row"}
            style={{
              padding: 10,
              paddingLeft: 10,
              paddingRight: 10,
              cursor: "pointer"
            }}
            key={poster.id}
            onClick={async () => {
              await savePosterAction(poster, props.event);
              props.history.push(
                `/public/event/${props.event.id}/poster/${poster.id}`
              );
            }}
          >
            <Grid
              container
              item
              xs
              direction={"row"}
              alignItems={"center"}
              style={{
                marginLeft: 10,
                marginRight: 10,
                paddingBottom: 15,
                paddingTop: 10,
                borderBottom: "1px solid grey"
              }}
            >
              <div style={{ marginRight: 15, width: 200 }}>
                <AttachmentPreview
                  attachmentLink={poster.fileUrl}
                  filename={poster.fileName}
                />
              </div>

              <Grid
                xs
                item
                container
                direction={"column"}
                justify={"center"}
                alignContent={"flex-start"}
              >
                {poster.order ? (
                  <div
                    style={{
                      fontSize: 16,
                      marginBottom: 5
                    }}
                  >
                    <span style={{ color: props.event.titleColor || "black" }}>
                      <span style={{marginRight: 5}}>Número de poster:</span>
                      {poster.order.toString().padStart(2, "0")}
                    </span>
                  </div>
                ) : null}
                <div
                  style={{
                    fontSize: 25,
                    marginBottom: 5
                  }}
                >
                  <span style={{ color: props.event.titleColor || "black" }}>
                    {poster.name}
                  </span>
                </div>
                  {poster.code ? (
                      <span
                          style={{ color: props.event.workshopColor || "black" }}
                      >
                      {poster.code
                          ? 'Código:' : null}
                    </span>
                  ) : null}
                  {poster.code ? poster.code : null}
                <div
                  style={{
                    fontSize: 20,
                    marginBottom: 5
                  }}
                >
                    { poster.authors ? <span style={{ color: props.event.authorsColor || "black" }}>
                    Autores:
                  </span> : null }
                  {poster.authors ? poster.authors : null}
                </div>
                <div
                  style={{
                    fontSize: 20
                  }}
                >
                  {poster.workshop ? (
                    <span
                      style={{ color: props.event.workshopColor || "black" }}
                    >
                      {poster.workshopLabel
                        ? poster.workshopLabel
                        : poster.workshop
                        ? "Centro de trabajo"
                        : null}
                    </span>
                  ) : null}
                  {poster.workshop ? poster.workshop : null}
                </div>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </div>
      {navBar}
    </Grid>
  );
};
const mapStateToProps = state => ({
  postersFromEvent: state.postersReducer.posterFromEventList,
  postersFromCategory: state.postersReducer.posterFromCategoryList,
  filteredPosters: state.postersReducer.filteredPosters,
  event: state.eventsReducer.selectedEvent
});

export default connect(
  mapStateToProps,
  {getPostersFromCategory, getPostersFromEvent, getEvent, filterPosters}
)(withRouter(PostersSection));
