// @flow
import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import {Poster} from "../../model/Poster";
import {countPosterActions, getPosterActions, updatePosterActionsQuery} from "../../redux/modules/posterActionsModule";
import EventsSelector from "../events/EventsSelector";
import {getPostersFromEvent} from "../../redux/modules/postersModule";
import PostersSelector from "../posters/PostersSelector";
import {DateTime} from "luxon";
import {KeyboardDatePicker} from "@material-ui/pickers";

type Props = {
    query: string,
    from: DateTime,
    to: DateTime,
    history: any,
    posters: Poster[],
    events: Event[],
    eventId: string,
    posterId: string,
    getPosterActions: () => void,
    getPostersFromEvent: (eventId: string) => void,
    updatePosterActionsQuery: (query: {eventId: string, posterId: string}) => void,
    countPosterActions: () => void,
};
const PosterActionsActionBar = (props: Props) => {
    console.log(props.events);
  return (
    <Paper style={{ marginBottom: 10 }}>
      <Grid container
            direction={"row"}
            justify={"flex-end"}
            alignItems={'center'}
            style={{
                padding: 20
            }}
      >

          <KeyboardDatePicker
              style={{marginLeft: 10, marginRight: 10}}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id="start-date"
              label="Desde"
              value={props.from}
              onChange={date => {
                  const currentQuery = {eventId: props.eventId, posterId: props.posterId, from: date, to: props.to};
                  props.updatePosterActionsQuery(currentQuery);
                  props.getPosterActions();
              }}
              KeyboardButtonProps={{
                  "aria-label": "change date"
              }}
          />

          <KeyboardDatePicker
              style={{marginLeft: 10, marginRight: 10}}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id="end-date"
              label="Hasta"
              value={props.to}
              onChange={date => {
                  const currentQuery = {eventId: props.eventId, posterId: props.posterId, from: props.from, to: date};
                  props.updatePosterActionsQuery(currentQuery);
                  props.getPosterActions();
              }}
              KeyboardButtonProps={{
                  "aria-label": "change date"
              }}
          />

          <div style={{marginRight: 10}}>Eventos</div>
          <EventsSelector events={props.events} currentEventId={props.eventId || ''} onEventSelected={(eventName) => {
              props.events.forEach((event) => {
                  if(event.name.toLowerCase() === eventName.toLowerCase()) {
                      const currentQuery = {eventId: event.id, posterId: props.posterId, from: props.from, to: props.to};
                      props.updatePosterActionsQuery(currentQuery);
                      props.getPosterActions();
                      props.countPosterActions();
                      props.getPostersFromEvent(event.id);
                  }
              });
          }}/>

          {
              props.posters && props.posters.length > 0 ? <div style={{marginRight: 10, marginLeft: 10}}>Posters</div> : null }
          {
              props.posters && props.posters.length > 0 ? <PostersSelector posters={props.posters} currentEventId={props.posterId || ''} onPosterSelected={(posterName) => {
                  props.posters.forEach((poster) => {
                      if(poster.name.toLowerCase() === posterName.toLowerCase()) {
                          const currentQuery = {eventId: props.eventId, posterId: poster.id, from: props.from, to: props.to};
                          props.updatePosterActionsQuery(currentQuery);
                          props.getPosterActions();
                          props.countPosterActions();
                      }
                  });
              }}/> : null
          }
          <Button style={{marginLeft: 5}} onClick={() => {
              props.updatePosterActionsQuery({eventId: null, posterId: null, from: null, to: null});
              props.getPosterActions();
              props.countPosterActions();
          }}>
              <Close/>
          </Button>
      </Grid>
    </Paper>
  );
};
const mapStateToProps = (state) => ({
    posterId: state.posterActionsReducer.posterId,
    eventId: state.posterActionsReducer.eventId,
    from: state.posterActionsReducer.from,
    to: state.posterActionsReducer.to,
    posters: state.postersReducer.posterFromEventList,
    events: state.eventsReducer.eventsList,
});
export default connect(mapStateToProps, {updatePosterActionsQuery, getPosterActions, countPosterActions, getPostersFromEvent})(PosterActionsActionBar);
