// @flow
import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getEvent} from "../redux/modules/eventsModule";
import {getCategories} from "../redux/modules/categoriesModule";
import {getPostersFromEvent} from "../redux/modules/postersModule";
import {Category} from "../model/Category";
import {Poster} from "../model/Poster";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import CategoriesSection from "./categories/CategoriesSection";
import PostersSection from "./posters/PostersSection";
import Sync from "@material-ui/icons/Sync";
import Button from "@material-ui/core/Button";
import {syncData} from "../redux/modules/syncDataAction";

type Props = {
    match: any,
    getEvent: (id: string) => void,
    getCategories: (id: string) => void,
    getPostersFromEvent: (id: string) => void,
    event: Event,
    categories: Category[],
    posters: Poster[],
};

const PublicEventSection = (props: Props) => {

    useEffect(() => {
        props.getEvent(props.match.params.id);
        props.getCategories(props.match.params.id);
        props.getPostersFromEvent(props.match.params.id);
    }, []);

    if(!props.event)
        return (
        <Grid
            container
            direction={"row"}
            justify={"center"}
            style={{ margin: 20 }}
        >
            <CircularProgress />
        </Grid>
    );

    return (
        <Grid container direction={'column'}>
            {props.event.eventBannerUrl ? <img src={props.event.eventBannerUrl} style={{width: '100%', height: 100, objectFit: 'cover'}}/>
            :
                <Grid direction={'row'} justify={'center'} style={{
                background: props.event.titleColor || 'grey',
                padding: 40,
                fontSize: 40,
                color: 'white',
                textAlign: 'center'
            }}>
                Banner de {props.event.name}
            </Grid>}

                <Button style={{
                    position: 'fixed',
                    top: 5,
                    right: 5
                }} onClick={async () => {
                    if(!props.match.params.id) return;
                        await syncData(props.match.params.id);
                }}><Sync style={{color: 'white', background: 'black', borderRadius: 100}}/></Button>


            {props.categories && props.categories.length > 0 ? <CategoriesSection/> : <PostersSection/>}

        </Grid>
    );
};

const mapStateToProps = state => ({
    event: state.eventsReducer.selectedEvent,
    categories: state.categoriesReducer.categoriesList,
    posters: state.postersReducer.posterList
});

export default connect(mapStateToProps, {getEvent, getCategories, getPostersFromEvent})(withRouter(PublicEventSection));
