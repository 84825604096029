import {Api} from "../../services/api";
import {DateTime} from "luxon";

export const GET_USER_ACTIONS = 'GET_USER_ACTIONS';
export const UPDATE_ACTIONS_QUERY = 'UPDATE_ACTIONS_QUERY';
export const COUNT_USER_ACTIONS = 'COUNT_USER_ACTIONS';

const API_PATH = '/UserActions';

const initState = {
    userId: '',
    from: null,
    to: null,
    userActionsList: [],
    userActionsCount: 0,
    loading: false,
};

type Action = {
    type: string,
    payload: any
};

//NON DISPATCH
export const saveUserAction = async(user) => {
    await Api.instance.post(API_PATH, {
        appUserId: user.id,
        userEmail: user.email,
        action: 'USER_ACCESS'
    });
};



const composeFilter = (reducer: any) => {
    const userId = reducer.userId;
    const from = reducer.from;
    const to = reducer.to;

    let filter;
    let queries = [];

    if(userId || from || to) {
        if(userId)
            queries.push({appUserId: userId});
        if(from)
            queries.push({createdAt: {gte: from}});
        if(to)
            queries.push({createdAt: {lte: to}});

        filter = {where: {and: queries}};
    }
    return filter;
};

export const countUserActions = () => async (dispatch, getState) => {
    const {userActionsReducer, authReducer} = getState();
    const currentUser = authReducer.currentUser;

    if(currentUser.role !== 'admin' && !userActionsReducer.userId)
        return dispatch({
            type: COUNT_USER_ACTIONS,
            payload: 0
        });

    try {
        const filter = composeFilter(userActionsReducer);
        const queryUrl = filter ? `?filter=${JSON.stringify(filter)}` : '';
        const response = await Api.instance.get(API_PATH + '/count' + queryUrl);
        dispatch({
            type: COUNT_USER_ACTIONS,
            payload: response.data.count
        });
    } catch (e) {
        console.log(e);
    }
};

export const getUserActions = () => async (dispatch, getState) => {
    const {userActionsReducer, authReducer} = getState();
    const currentUser = authReducer.currentUser;

    if(currentUser.role !== 'admin' && !userActionsReducer.userId)
        return dispatch({
            type: GET_USER_ACTIONS,
            payload: []
        });

    try {
        const filter = composeFilter(userActionsReducer);
        const queryUrl = filter ? `?filter=${JSON.stringify(filter)}` : '';
        const response = await Api.instance.get(API_PATH + queryUrl);
        dispatch({
            type: GET_USER_ACTIONS,
            payload: response.data
        });
    } catch (e) {
        console.log(e);
    }
};

export const updateActionsQuery = (query: {userId: string, from: DateTime, to: DateTime}) => async (dispatch) => {
    dispatch({
        type: UPDATE_ACTIONS_QUERY,
        payload: query
    });
};

export const userActionsReducer = (state = initState, action: Action) => {
    switch (action.type) {
        case GET_USER_ACTIONS:
            return {
                ...state,
                userActionsList: action.payload
            };
        case UPDATE_ACTIONS_QUERY:
            return {
                ...state,
                userId: action.payload.userId,
                from: action.payload.from,
                to: action.payload.to,
            };
        case COUNT_USER_ACTIONS:
            return {
                ...state,
                userActionsCount: action.payload
            };
        default:
            return state;
    }
};
