// @flow
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {Poster} from "../../../model/Poster";
import {Event} from "../../../model/Event";
import {
    deletePoster, getPoster,
    togglePosterDeleteDialog, updatePoster,
    updatePosterFile, updatePosterParams,
    uploadPosterFile
} from "../../../redux/modules/postersModule";
import {getEvents} from "../../../redux/modules/eventsModule";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getCategories} from "../../../redux/modules/categoriesModule";
import {Category} from "../../../model/Category";
import {AttachmentPreview} from "../../../components/AttachmentPreview/AttachmentPreview";
import EventsSelector from "../../../components/events/EventsSelector";
import {BASE_URL} from "../../../configs";

const formFieldStyles = {
  margin: 10
};

type Props = {
  match: any,
  poster: Poster,
  showDeleteConfirmation: boolean,
  getPoster: (id: string) => void,
  updatePoster: (poster: Poster) => void,
  deletePoster: () => void,
  togglePosterDeleteDialog: (open: boolean) => void,
  updatePosterParams: (poster: Poster) => void,
  updatePosterFile: (file: any) => void,
  uploadPosterFile: (poster: Poster, file: any) => void,
  selectedFile: any,
    events: Event[],
    getEvents: () => void,
    categories: Category[],
};

const PosterDetails = (props: Props) => {
  useEffect(() => {
    props.updatePosterParams({workshopLabel: 'Centro de Trabajo'});
    props.getEvents();
    if(props.match.params.posterId && props.match.params.posterId === 'new') {
        props.updatePosterParams({eventId: props.match.params.id, workshopLabel: 'Centro de Trabajo'});
    } else
        props.getPoster(props.match.params.posterId ? props.match.params.posterId : props.match.params.id);
  }, []);

  if (!props.poster || props.loading)
    return (
      <Grid
        container
        direction={"row"}
        justify={"center"}
        style={{ margin: 20 }}
      >
        <CircularProgress />
      </Grid>
    );

  const fromEventId = props.match.params.posterId ? props.match.params.id : null;
  let selectedEvent: Event;

  /*if(fromEventId)
      props.updatePosterParams({eventId: fromEventId});*/

  if(props.events && fromEventId) {
      props.events.forEach((e) => {
          if(e.id.toString() === fromEventId)
              selectedEvent = e;
      });
  }



  return (
    <Grid direction={"column"}>
      <Grid container direction={"row"} wrap={"wrap"} justify={"center"}>
        <Grid
          item
          xs={12}
          md={7}
          style={{
            padding: 20
          }}
        >
          <Paper
            style={{
              padding: 40
            }}
          >
            <Grid container direction={"column"} alignItems={"center"}>
              <div style={{ fontSize: 20, fontWeight: 200 }}>
                {props.poster.id ? props.poster.name : "Nuevo Poster"}
              </div>
              <Grid
                container
                direction={"row"}
                wrap={"wrap"}
                justify={"space-around"}
                alignItems={"center"}
                style={{
                  marginTop: 20
                }}
              >
              <Grid item style={formFieldStyles}>
                  <TextField
                      id="order"
                      label="Orden"
                      value={props.poster.order || ''}
                      onChange={event => {
                          const data = { ...props.poster };
                          data.order = event.target.value;
                          props.updatePosterParams(data);
                      }}
                  />
              </Grid>
                  <Grid item style={formFieldStyles}>
                      <TextField
                          id="order"
                          label="Código"
                          value={props.poster.code || ''}
                          onChange={event => {
                              const data = { ...props.poster };
                              data.code = event.target.value;
                              props.updatePosterParams(data);
                          }}
                      />
                  </Grid>

                <Grid item style={formFieldStyles}>
                  <TextField
                    id="name"
                    label="Nombre"
                    value={props.poster.name || ""}
                    onChange={event => {
                      const data = { ...props.poster };
                      data.name = event.target.value;
                      props.updatePosterParams(data);
                    }}
                  />
                </Grid>
                  <Grid item style={formFieldStyles}>
                      <TextField
                          id="authors"
                          label="Autores"
                          value={props.poster.authors || ""}
                          onChange={event => {
                              const data = { ...props.poster };
                              data.authors = event.target.value;
                              props.updatePosterParams(data);
                          }}
                      />
                  </Grid>

                  <Grid item style={formFieldStyles}>
                      <TextField
                          id="workshops"
                          label="Nombre taller"
                          value={props.poster.workshopLabel || ""}
                          onChange={event => {
                              const data = { ...props.poster };
                              data.workshopLabel = event.target.value;
                              props.updatePosterParams(data);
                          }}
                      />
                  </Grid>
                  <Grid item style={formFieldStyles}>
                      <TextField
                          id="workshops"
                          label="Taller"
                          value={props.poster.workshop || ""}
                          onChange={event => {
                              const data = { ...props.poster };
                              data.workshop = event.target.value;
                              props.updatePosterParams(data);
                          }}
                      />
                  </Grid>
                  {props.events ? (
                      <Grid container direction={'column'} item xs style={{paddingLeft: 20, paddingRight: 20}} alignContent={'center'}>
                          <div style={{fontSize: 20}}>Evento</div>
                          { fromEventId ? <div style={{padding: 5}}>{selectedEvent ? selectedEvent.name : ''}</div> : <EventsSelector currentEventId={props.poster.eventId} events={props.events} onEventSelected={(event) => {
                              props.events.forEach((e) => {
                                  if(e.name.toLowerCase() === event.toLowerCase()){
                                      const data = { ...props.poster };
                                      data.eventId = e.id;
                                      props.getCategories(e.id);
                                      props.updatePosterParams(data);
                                  }
                              });
                          }}/> }
                      </Grid>
                  ) : (
                      <CircularProgress />
                  )}
                  {props.categories && props.categories.length > 0 ? (
                      <FormControl id="category">
                          <InputLabel htmlFor="category">Categoria</InputLabel>
                          <Select
                              style={{minWidth: 200}}
                              value={props.poster.categoryId || ""}
                              onChange={event => {
                                  const data = { ...props.poster };
                                  data.categoryId = event.target.value;
                                  props.updatePosterParams(data);
                              }}
                              inputProps={{
                                  name: "category",
                                  id: "category"
                              }}
                          >
                              {props.categories.map(category => (
                                  <MenuItem key={category.id} value={category.id}>
                                      {category.name}
                                  </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                  ) : (
                      null
                  )}
              </Grid>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{
                  marginTop: 20
                }}
                disabled={!props.poster.name}
                onClick={() => {
                  props.updatePoster(props.poster);
                }}
              >
                  {props.poster.id ? 'Editar' : 'Crear'}
              </Button>
              <Button
                color={"secondary"}
                style={{
                  marginTop: 20
                }}
                onClick={() => {
                  props.togglePosterDeleteDialog(true);
                }}
              >
                Eliminar
              </Button>
              {props.poster.id ? (
                <Grid
                  container
                  direction={"column"}
                  alignItems={"center"}
                  style={{
                    marginTop: 40
                  }}
                >
                  <Grid
                    container
                    direction={"row"}
                    justify={"center"}
                    style={{ fontSize: 20, marginBottom: 20 }}
                  >
                    Archivo de poster
                  </Grid>
                  <Grid
                    container
                    direction={"row"}
                    wrap={"wrap"}
                    justify={"space-around"}
                  >
                    <Grid
                      container
                      item
                      direction={"column"}
                      xs
                      alignItems={"center"}
                    >
                      <div style={{ fontSize: 20, marginBottom: 10 }}>
                        {props.poster.fileUrl
                          ? "Sustituir archivo"
                          : "Subir nuevo "}
                      </div>
                      <input
                        type="file"
                        name="file"
                        onChange={event => {
                          props.updatePosterFile(event.target.files[0]);
                        }}
                      />

                      <Button
                        variant={"contained"}
                        color={"primary"}
                        style={{
                          marginTop: 20
                        }}
                        disabled={!props.poster.name}
                        onClick={() => {
                          props.uploadPosterFile(
                            props.poster,
                            props.selectedFile
                          );
                        }}
                      >
                        Guardar Adjunto
                      </Button>
                    </Grid>
                    <Grid
                      container
                      item
                      direction={"column"}
                      xs
                      alignItems={"center"}
                    >
                      <div style={{ fontSize: 20, marginBottom: 10 }}>
                        Actual
                      </div>

                      <div>
                        {props.poster.fileUrl && props.poster.fileName ? (
                          <div style={{ marginTop: 10 }}>
                            <div
                              style={{
                                textDecoration: "none",
                                color: "black",
                                padding: 10,
                                background: "#eeeeee",
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                window.open(
                                    BASE_URL + props.poster.fileUrl,
                                  "_blank"
                                );
                              }}
                            >
                                <AttachmentPreview attachmentLink={props.poster.fileUrl} filename={props.poster.fileName}/>
                            </div>
                          </div>
                        ) : (
                          <div>El poster no tiene archivo.</div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={props.showDeleteConfirmation}
        onClose={() => {
          props.togglePosterDeleteDialog(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Confirmación"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Deseas eliminar este poster?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.togglePosterDeleteDialog(false);
            }}
            color="primary"
          >
            cancelar
          </Button>
          <Button
            onClick={() => {
              props.deletePoster();
            }}
            color="secondary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const mapStateToProps = state => ({
  poster: state.postersReducer.selectedPoster,
  selectedFile: state.postersReducer.selectedPosterFile,
  loading: state.postersReducer.loading,
  showDeleteConfirmation: state.postersReducer.showDeleteConfirmation,
    events: state.eventsReducer.eventsList,
    categories: state.categoriesReducer.categoriesList
});

export default connect(
  mapStateToProps,
  {
    getPoster,
    updatePoster,
    updatePosterParams,
    updatePosterFile,
    uploadPosterFile,
    deletePoster,
    togglePosterDeleteDialog,
      getEvents,
      getCategories
  }
)(withRouter(PosterDetails));
