import { Api } from "../../services/api";
import { Category } from "../../model/Category";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
/*export const UPDATE_CATEGORY_PARAMS = "UPDATE_CATEGORY_PARAMS";*/
export const DELETE_CATEGORY = "DELETE_CATEGORY";

const PARENT_PATH = "/Events";
const CHILD_PATH = "/categories";
const API_PATH = "/Categories";

const initState = {
  categoriesList: [],
  loading: false,
  selectedCategory: new Category()
};

type Action = {
  type: string,
  payload: any
};

/*export const updateCategoryParams = category => dispatch => {
  dispatch({
    type: UPDATE_CATEGORY_PARAMS,
    payload: category
  });
};*/

export const deleteCategory = category => async (dispatch, getState) => {
  const { eventsReducer } = getState();
  try {
    await Api.instance.delete(
      PARENT_PATH +
        `/${eventsReducer.selectedEvent.id}` +
        CHILD_PATH +
        "/" +
        category.id
    );
    const listResponse = await Api.instance.get(
        PARENT_PATH + `/${eventsReducer.selectedEvent.id}` + CHILD_PATH
    );
    dispatch({
      type: GET_CATEGORIES,
      payload: listResponse.data
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateCategory = category => async (dispatch, getState) => {
  const { eventsReducer } = getState();
  try {
    await Api.instance.post(
        API_PATH + '/replaceOrCreate',
        {...category, eventId: category.eventId || eventsReducer.selectedEvent.id}
    );
    const listResponse = await Api.instance.get(
        PARENT_PATH + `/${eventsReducer.selectedEvent.id}` + CHILD_PATH
    );
    dispatch({
      type: GET_CATEGORIES,
      payload: listResponse.data
    });
  } catch (e) {
    console.log(e);
  }
};

export const clearCategories = () => async dispatch => {
  dispatch({
    type: GET_CATEGORIES,
    payload: []
  });
};

export const fetchCategories = async eventId => {
  return await Api.instance.get(
      PARENT_PATH + `/${eventId}` + CHILD_PATH
  );
};

export const getCategories = eventId => async dispatch => {
  try {
    const response = await fetchCategories(eventId);

    dispatch({
      type: GET_CATEGORIES,
      payload: response.data
    });
  } catch (e) {
    console.log(e);
  }
};

export const categoriesReducer = (state = initState, action: Action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      if (action.payload) {
        return {
          ...state,
          categoriesList: action.payload
        };
      } else {
        return {
          ...state
        };
      }
    case UPDATE_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload
      };
    /*case UPDATE_CATEGORY_PARAMS:
      return {
        ...state,
        selectedCategory: { ...action.payload }
      };*/
    case DELETE_CATEGORY:
      return {
        ...state
      };
    default:
      return state;
  }
};
