// @flow
import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Search from "@material-ui/icons/Search";
import {getEvents, updateEventQuery} from "../../redux/modules/eventsModule";

type Props = {
    query: string;
    updateEventQuery: (query: string) => void;
    history: any,
    getEvents: (query: string) => void
};
const EventsActionBar = (props: Props) => {
  return (
    <Paper style={{ marginBottom: 10 }}>
      <Grid container
            direction={"row"}
            justify={"flex-end"}
            style={{
                padding: 20
            }}
      >
          <TextField
              id="query"
              label=""
              value={props.query}
              variant={"outlined"}
              onKeyPress={(event) => {
                  if(event.key === 'Enter')
                      props.getEvents(props.query);
              }}
              onChange={(event) => {
                  props.updateEventQuery(event.target.value);
              }}
          />
          <Button onClick={() => {
              props.getEvents(props.query);
          }} style={{
              marginLeft: 10, marginRight: 10
          }}><Search/></Button>
        <Button onClick={() => {
            props.history.push('/home/event/new');
        }}>Nuevo</Button>
      </Grid>
    </Paper>
  );
};
const mapStateToProps = (state) => ({
    query: state.eventsReducer.eventListQuery
});
export default connect(mapStateToProps, {updateEventQuery, getEvents})(withRouter(EventsActionBar));
