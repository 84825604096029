// @flow
import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getPoster} from "../../redux/modules/postersModule";
import {Poster} from "../../model/Poster";
import {Event} from "../../model/Event";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachmentView from "../../components/AttatchmentView/AttachmentView";
import {getEvent} from "../../redux/modules/eventsModule";

type Props = {
    match: {params: {posterId: number}},
    poster: Poster,
    event: Event,
    getPoster: (id: number) => void
};
const PosterDetailsSection = (props: Props) => {
    useEffect(() => {
        props.getEvent(props.match.params.id);
        props.getPoster(props.match.params.posterId)
    }, []);

    if (!props.poster || !props.event)
        return (
            <Grid
                container
                direction={"row"}
                justify={"center"}
                style={{ margin: 20 }}
            >
                <CircularProgress />
            </Grid>
        );

    const height = window.innerHeight;
    return (
        <div>
            <div style={{

            }}>
                <AttachmentView attachmentLink={props.poster.fileUrl} filename={props.poster.fileName} height={height - 50} containerStyle={{}} printable={props.event.printPosters}/>
                {props.poster.order ? <div style={{position: 'absolute', top: 20, right: 20, background: props.event.titleColor, borderRadius: 10, padding: 20, color: "white"}}>
                    {props.poster.order.toString().padStart(2, '0')}
                </div> : null}
            </div>

        </div>
    );
};

const mapStateToProps = state => ({
    poster: state.postersReducer.selectedPoster,
    event: state.eventsReducer.selectedEvent,
});

export default connect(mapStateToProps, {getPoster, getEvent})(withRouter(PosterDetailsSection));
