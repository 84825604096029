// @flow
import * as React from 'react';
import Users from "./pages/Users/Users";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import UserDetails from "./pages/Users/UserDetails";
import Events from "./pages/Events/Events";
import EventDetails from "./pages/Events/EventDetails";
import Posters from "./pages/Posters/Posters";
import PosterDetails from "./pages/Posters/PosterDetails";
import UserActions from "./pages/Reports/UserActions";
import PosterActions from "./pages/Reports/PosterActions";

type Props = {

};
const DashboardRoutes = (props: Props) => {
    return (
        <Switch>
            <Route path="/home/posters" exact component={Posters}/>
            <Route path="/home/poster/:id" exact component={PosterDetails}/>
            <Route path="/home/users" exact component={Users}/>
            <Route path="/home/user/:id" exact component={UserDetails}/>
            <Route path="/home/events" exact component={Events}/>
            <Route path="/home/event/:id" exact component={EventDetails}/>
            <Route path="/home/event/:id/user/:userId" exact component={UserDetails}/>
            <Route path="/home/event/:id/poster/:posterId" exact component={PosterDetails}/>
            <Route path="/home/userActions" exact component={UserActions}/>
            <Route path="/home/posterActions" exact component={PosterActions}/>

            <Redirect from="/home" exact to="/home/users"/>
        </Switch>
    );
};

export default withRouter(DashboardRoutes);
