// @flow
import * as React from 'react';
import {Document, Page} from 'react-pdf'
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
    fileUrl: string,
    height: number,
    width: number,
};
export const PdfPreview = (props: Props) => {
    return (
        <Document
            file={props.fileUrl}
            onLoadSuccess={({numPages}) => {
                console.log(numPages)
            }}
        >
            <Page pageNumber={1} height={props.height || 100} width={props.width || null} />
        </Document>
    );
};
