// @flow
import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {Link, Redirect, withRouter} from "react-router-dom";
import Close from "@material-ui/icons/Close";
import Collections from "@material-ui/icons/Collections";
import Event from "@material-ui/icons/Event";
import Person from "@material-ui/icons/Person";
import TrendingUp from "@material-ui/icons/TrendingUp";
import {connect} from "react-redux";
import {AppUser} from "../model/AppUser";
import {getLoggedUser, logoutAction} from "../redux/modules/authModule";

type Props = {
    logoutAction: () => void,
    getLoggedUser: () => AppUser
};

const linkStyle = {
    textDecoration: 'none',
    color: 'black'
};

const SideMenu = (props: Props) => {

    const user = props.getLoggedUser();
    if(user === null)
        return <Redirect to={'/'}/>;

    const isAdmin = user && user.role === 'admin';
    const isEventAdmin = user && user.role === 'eventAdmin';
    return (
        <Grid container direction={"column"}>
            {isAdmin ?
                <Link to={'/home/users'} style={linkStyle}>
                <ListItem button key={'users'}>
                <ListItemIcon><Person/></ListItemIcon>
                <ListItemText primary={'Usuarios'}/>
                </ListItem>
                </Link> : null }
            <Link to={'/home/events'} style={linkStyle}>
                <ListItem button key={'events'}>
                    <ListItemIcon><Event/></ListItemIcon>
                    <ListItemText primary={'Eventos'} />
                </ListItem>
            </Link>
            {isAdmin ? <Link to={'/home/posters'} style={linkStyle}>
                <ListItem button key={'posters'}>
                    <ListItemIcon><Collections/></ListItemIcon>
                    <ListItemText primary={'Posters'} />
                </ListItem>
            </Link> : null}
            {isAdmin || isEventAdmin ?
            <Link to={'/home/userActions'} style={linkStyle}>
                <ListItem button key={'userActions'}>
                    <ListItemIcon><TrendingUp/></ListItemIcon>
                    <ListItemText primary={'Acceso de Usuarios'} />
                </ListItem>
            </Link> : null }
            {isAdmin ?
            <Link to={'/home/posterActions'} style={linkStyle}>
                <ListItem button key={'posterActions'}>
                    <ListItemIcon><TrendingUp/></ListItemIcon>
                    <ListItemText primary={'Acceso a Posters'} />
                </ListItem>
            </Link> : null }
            <ListItem button key={'logout'} onClick={() => {
                props.logoutAction();
                props.history.push('/');
            }}>
                <ListItemIcon><Close/></ListItemIcon>
                <ListItemText primary={'Cerrar sesión'} />
            </ListItem>
           {/* <Link to={'/home/users'} style={linkStyle}>
                <ListItem button key={'categores'}>
                    <ListItemIcon><Category/></ListItemIcon>
                    <ListItemText primary={'Categorias'} />
                </ListItem>
            </Link>*/}
        </Grid>
    );
};

const mapStateToPros = state => ({

});

export default connect(mapStateToPros, {getLoggedUser, logoutAction})(withRouter(SideMenu));
