// @flow
import React from 'react';
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Event} from '../../model/Event'
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import {AppUser} from "../../model/AppUser";
import {addUserEvent, deleteUserEvent, updateUserEventParams} from "../../redux/modules/usersModule";
import CircularProgress from "@material-ui/core/CircularProgress";
import EventsSelector from "../events/EventsSelector";

type Props = {
    user: AppUser,
    events: Event[],
    userEvents: Event[],
    selectedUserEvent: Event,
    updateUserEventParams: (event: Event) => void,
    deleteUserEvent: (event: Event) => void,
    addUserEvent: () => void
};
const UserEvents = (props: Props) => {
    console.log(props.events);
    return (
        <Grid container direction={"column"} style={{paddingLeft: 16, paddingRight: 16}}>
            <div style={{
                marginBottom: 10
            }}>
                <Grid container direction={"column"}>
                    <Grid container direction={"row"}>
                        {props.events ? (
                            <Grid item xs container direction={'row'} justify={'flex-end'} style={{paddingRight: 10}}>
                                <EventsSelector events={props.events} currentEvent={props.selectedUserEvent} onEventSelected={(event) => {
                                    props.events.forEach((e) => {
                                        if(e.name.toLowerCase() === event.toLowerCase()){
                                            props.updateUserEventParams(e);
                                        }
                                    });
                                }}/>
                            </Grid>
                        ) : (
                            <CircularProgress />
                        )}
                        <Button onClick={() => {
                            props.addUserEvent();
                        }}>Agregar</Button>
                    </Grid>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align={"left"}>Eventos</TableCell>
                                <TableCell align={"left"}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.userEvents.map(event => (
                                <TableRow key={event.id} style={{cursor: 'pointer'}}>
                                    <TableCell align={"left"}>
                                        {event.name}
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        <Button onClick={() => {
                                            props.deleteUserEvent(event);
                                        }}><Close/></Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </div>
        </Grid>
    );
};

const mapStateToProps = state => ({
    user: state.usersReducer.selectedUser,
    events: state.eventsReducer.eventsList,
    userEvents: state.usersReducer.selectedUserEvents,
    selectedUserEvent: state.usersReducer.selectedUserEventParams
});

export default connect(mapStateToProps, {deleteUserEvent, updateUserEventParams, addUserEvent})(UserEvents);
