// @flow
import React, {useEffect, useState} from 'react';
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {updatePosterParams} from "../../redux/modules/postersModule";
import {Poster} from "../../model/Poster";
import {Event} from "../../model/Event";
import TablePagination from "@material-ui/core/TablePagination";

type Props = {
    event: Event,
    posters: Poster[],
    postersFromEvent: Poster[],
    updatePosterParams: (poster: Poster) => void
};
const PostersTable = (props: Props) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        props.updatePosterParams({});
    }, []);

    if(!props.posters)
        return (
            <Grid container direction={"column"}>
                <CircularProgress/>
            </Grid>
        );

    let data = [];

    if(props.event) {
        data = props.postersFromEvent || [];
    } else {
        data = props.posters || [];
    }

    const count = data.length;
    const records = [...data].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Grid container direction={"column"}>
            <Paper style={{}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={"left"}>Id</TableCell>
                            <TableCell align={"left"}>Nombre</TableCell>
                            <TableCell align={"left"}>Autores</TableCell>
                            <TableCell align={"left"}>Taller</TableCell>
                            <TableCell align={"left"}>Fecha de Creación</TableCell>
                            <TableCell align={"left"}>Ultima modificación</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map(poster => (
                            <TableRow key={poster.id} onClick={() => {
                                if(props.event) {
                                    props.history.push(`/home/event/${props.event.id}/poster/${poster.id}`);
                                } else {
                                    props.history.push(`/home/poster/${poster.id}`);
                                }
                            }} style={{cursor: 'pointer'}}>
                                <TableCell align={"left"}>
                                    {poster.id}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {poster.name}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {poster.authors}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {poster.worshop}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {poster.createdAt}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {poster.updatedAt}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={(event, newPage) => {
                        setPage(newPage);
                    }}
                    onChangeRowsPerPage={(event) => {
                        setRowsPerPage(+event.target.value);
                        setPage(0);
                    }}
                />
            </Paper>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    posters: state.postersReducer.posterList,
    postersFromEvent: state.postersReducer.posterFromEventList
});

export default connect(mapStateToProps, {updatePosterParams})(withRouter(PostersTable));
