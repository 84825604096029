// @flow
import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Category } from "../../model/Category";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

type Props = {
  categories: Category[],
  event: Event
};

const CategoriesSection = (props: Props) => {

  if (!props.categories)
    return (
      <Grid
        container
        direction={"row"}
        justify={"center"}
        style={{ margin: 20 }}
      >
        <CircularProgress />
      </Grid>
    );

  return (
    <Grid container direction={"column"}>
      {props.categories.map(category => (
        <Grid
          container
          direction={"row"}
          style={{
            padding: 10,
            cursor: "pointer"
          }}
          key={category.id}
          onClick={() => {
            props.history.push(
              `/public/event/${props.event.id}/category/${category.id}`
            );

          }}
        >
          <Grid container item xs direction={'row'} style={{ borderBottom: "1px solid grey", paddingTop: 10, paddingBottom: 15}}>
            { props.event && props.event.showOrder && category.order && category.order !== 0 ?
                <div
                    style={{
                      background: props.event.titleColor,
                      padding: 10,
                      borderRadius: 10,
                      width: 50,
                      marginRight: 15,
                      height: 50,
                      textAlign: "center",
                      color: "white",
                      fontSize: 40
                    }}
                >
                  {`${category.order}`.padStart(2, "0")}
                </div> : null
            }

            <Grid
                xs
                item
                container
                direction={"column"}
                justify={'center'}
                alignContent={"flex-start"}
            >
              <div
                  style={{
                    fontSize: 30,
                    color: props.event.titleColor || "black",
                    marginBottom: 5
                  }}
              >
                {category.name}
              </div>
              <div style={{ fontSize: 15 }}>{category.description}</div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const mapStateToProps = state => ({
  event: state.eventsReducer.selectedEvent,
  categories: state.categoriesReducer.categoriesList
});

export default connect(
  mapStateToProps,
  null
)(withRouter(CategoriesSection));
