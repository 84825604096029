// @flow
import React, {useEffect, useState} from 'react';
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {AppUser} from "../../model/AppUser";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {updateUserParams} from "../../redux/modules/usersModule";
import TablePagination from "@material-ui/core/TablePagination";

type Props = {
    users: AppUser[],
    updateUserParams: (user: AppUser) => void
};

const UsersTable = (props: Props) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        props.updateUserParams({});
    }, []);

    if(!props.users)
        return (
            <Grid container direction={"column"}>
                <CircularProgress/>
            </Grid>
        );

    const count = props.users.length;
    const records = [...props.users].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Grid container direction={"column"}>

            <Paper style={{}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={"left"}>Id</TableCell>
                            <TableCell align={"left"}>Nombre</TableCell>
                            <TableCell align={"left"}>Email</TableCell>
                            <TableCell align={"left"}>Rol</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map(user => (
                            <TableRow key={user.id} onClick={() => {
                                props.history.push(`/home/user/${user.id}`);
                            }} style={{cursor: 'pointer'}}>
                                <TableCell align={"left"}>
                                    {user.id}
                                </TableCell>
                                <TableCell align={"left"}>
                                    {user.username}
                                </TableCell>
                                <TableCell align={"left"}>{user.email}</TableCell>
                                <TableCell align={"left"}>{user.role}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={(event, newPage) => {
                        setPage(newPage);
                    }}
                    onChangeRowsPerPage={(event) => {
                        setRowsPerPage(+event.target.value);
                        setPage(0);
                    }}
                />
            </Paper>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    users: state.usersReducer.usersList
});

export default connect(mapStateToProps, {updateUserParams})(withRouter(UsersTable));
