// @flow
import * as React from 'react';
import {connect} from "react-redux";
import Select from 'react-select';

type Props = {
    events: Event[],
    currentEvent: Event,
    currentEventId: number,
    onEventSelected: (eventName: string) => void
};
const EventsSelector = (props: Props) => {

    if(!props.events)
        return null;

    let currentEvent = props.currentEvent;

    if(props.currentEventId)
        props.events.forEach((e) => {
            if(e.id === props.currentEventId)
                currentEvent = e;
        });

    return (
            <Select
                styles={{control: styles => ({ ...styles, width: 200 }),}}
                defaultValue={currentEvent ? currentEvent.name || '' : ''}
                label="Eventos"
                onChange={(data) => {
                    props.onEventSelected(data.value);
                }}
                options={props.events.map((event) => ({value: event.name, label: event.name}))}
            />
    );
};


export default connect( null, null)(EventsSelector);
