// @flow
import React, {useEffect, useState} from 'react';
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Event} from "../../model/Event";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {updateEventParams} from "../../redux/modules/eventsModule";
import {BASE_URL, CLIENT_URL} from "../../configs";
import TablePagination from "@material-ui/core/TablePagination";

type Props = {
    events: Event[],
    updateEventParams: (event: Event) => void
};
const EventsTable = (props: Props) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        props.updateEventParams({});
    }, []);

    if(!props.events)
        return (
            <Grid container direction={"column"}>
                <CircularProgress/>
            </Grid>
        );
    const count = props.events.length;
    const records = [...props.events].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return (
        <Grid container direction={"column"}>
            <Paper style={{}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={"left"}>Id</TableCell>
                            <TableCell align={"left"}>Activo</TableCell>
                            <TableCell align={"left"}>Nombre</TableCell>
                            <TableCell align={"left"}>Link</TableCell>
                            <TableCell align={"left"}>Fecha del Evento</TableCell>
                            <TableCell align={"left"}>Fecha Fin</TableCell>
                            <TableCell align={"left"}>Fecha de Creación</TableCell>
                            <TableCell align={"left"}>Ultima modificación</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map(event => (
                            <TableRow key={event.id} >
                                <TableCell align={"left"} style={{cursor: 'pointer'}} onClick={() => {
                                    props.history.push(`event/${event.id}`);
                                }}>
                                    {event.id}
                                </TableCell>
                                <TableCell align={"left"} style={{cursor: 'pointer'}} onClick={() => {
                                    props.history.push(`event/${event.id}`);
                                }}>
                                    {event.isActive ? 'Si' : 'No'}
                                </TableCell>
                                <TableCell align={"left"} style={{cursor: 'pointer'}} onClick={() => {
                                    props.history.push(`event/${event.id}`);
                                }}>
                                    {event.name}
                                </TableCell>
                                <TableCell align={"left"}>
                                    <Link to={'/public/event/'+ event.id}>{CLIENT_URL + `public/event/${event.id}`}</Link>
                                </TableCell>
                                <TableCell align={"left"} style={{cursor: 'pointer'}} onClick={() => {
                                    props.history.push(`event/${event.id}`);
                                }}>
                                    {event.eventDate}
                                </TableCell>
                                <TableCell align={"left"} style={{cursor: 'pointer'}} onClick={() => {
                                    props.history.push(`event/${event.id}`);
                                }}>
                                    {event.eventEndDate}
                                </TableCell >
                                <TableCell align={"left"} style={{cursor: 'pointer'}} onClick={() => {
                                    props.history.push(`event/${event.id}`);
                                }}>
                                    {event.createdAt}
                                </TableCell>
                                <TableCell align={"left"} style={{cursor: 'pointer'}} onClick={() => {
                                    props.history.push(`event/${event.id}`);
                                }}>
                                    {event.updatedAt}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={(event, newPage) => {
                        setPage(newPage);
                    }}
                    onChangeRowsPerPage={(event) => {
                        setRowsPerPage(+event.target.value);
                        setPage(0);
                    }}
                />
            </Paper>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    events: state.eventsReducer.eventsList
});

export default connect(mapStateToProps, {updateEventParams})(withRouter(EventsTable));
