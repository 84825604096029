import {combineReducers} from "redux";
import {authReducer} from "../modules/authModule";
import {usersReducer} from "../modules/usersModule";
import {eventsReducer} from "../modules/eventsModule";
import {categoriesReducer} from "../modules/categoriesModule";
import {postersReducer} from "../modules/postersModule";
import {userActionsReducer} from "../modules/userActionsModule";
import {posterActionsReducer} from "../modules/posterActionsModule";

const rootReducer = combineReducers({
    authReducer,
    usersReducer,
    eventsReducer,
    postersReducer,
    categoriesReducer,
    userActionsReducer,
    posterActionsReducer
});

export default rootReducer;
