import {CLOUDMERSIVE_API_CLIENT} from "../environment";

const CloudmersiveConvertApiClient = window.cloudmersiveConvertApiClient;

export class DocConversionApi {

    static apiInstance;

    static init() {
        const defaultClient = CloudmersiveConvertApiClient.ApiClient.instance;
        const Apikey = defaultClient.authentications['Apikey'];
        Apikey.apiKey = CLOUDMERSIVE_API_CLIENT;
        DocConversionApi.apiInstance = new CloudmersiveConvertApiClient.ConvertDocumentApi();
    }

    static async convertDocumentPptxToPdf(file) {

        if(!DocConversionApi.apiInstance)
            DocConversionApi.init();

        return new Promise(((resolve, reject) => {
            const callback = async(error, data, response) => {
                console.log(response.body);
                if (error) {
                    reject(error);
                } else {
                    resolve(response.body);
                }
            };
            DocConversionApi.apiInstance.convertDocumentPptxToPdf(file, callback);
        }));


    }
}
