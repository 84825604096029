// @flow
import * as React from 'react';
import {connect} from "react-redux";
import Select from 'react-select';
import {AppUser} from "../../model/AppUser";

type Props = {
    users: AppUser[],
    currentUser: AppUser,
    currentUserId: number,
    onUserSelected: (userEmail: string) => void
};

const UsersSelector = (props: Props) => {

    if(!props.users)
        return null;

    let currentUser: AppUser = props.currentUser;

    if(props.currentUserId)
        props.users.forEach((e) => {
            if(e.id === props.currentUserId)
                currentUser = e;
        });

    return (
        <Select
            styles={{control: styles => ({ ...styles, width: 200 }),}}
            defaultValue={currentUser ? currentUser.email || '' : ''}
            label="Usuarios"
            onChange={(data) => {
                props.onUserSelected(data.value);
            }}
            options={props.users.map((user) => ({value: user.email, label: user.email}))}
        />
    );
};

const mapStateToProps = state => ({
    users: state.usersReducer.usersList,
});

export default connect(mapStateToProps, null)(UsersSelector);
