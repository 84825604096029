// @flow
import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {connect} from "react-redux";
import {getUsers, updateUsersQuery} from "../../redux/modules/usersModule";
import {withRouter} from "react-router-dom";
import Search from "@material-ui/icons/Search";

type Props = {
    query: string;
    updateUsersQuery: (query: string) => void;
    history: any,
    eventId: string,
    getUsers: (query: string) => void
};
const UsersActionBar = (props: Props) => {
  return (
    <Paper style={{ marginBottom: 10 }}>
      <Grid container
            direction={"row"}
            justify={"flex-end"}
            style={{
                padding: 20
            }}
      >
          {props.eventId ? null : <Grid direction={'row'} item xs container>
              <TextField
                  id="query"
                  label=""
                  value={props.query || ''}
                  variant={"outlined"}
                  onKeyPress={(event) => {
                      if(event.key === 'Enter')
                          props.getUsers(props.query);
                  }}
                  onChange={(event) => {
                      props.updateUsersQuery(event.target.value);
                  }}
              />
              <Button onClick={() => {
                  props.getUsers(props.query);
              }} style={{
                  marginLeft: 10, marginRight: 10
              }}><Search/></Button>
          </Grid> }
        <Button onClick={() => {
            props.history.push(props.eventId ? `/home/event/${props.eventId}/user/new` : '/home/user/new');
        }}>Nuevo</Button>
      </Grid>
    </Paper>
  );
};
const mapStateToProps = (state) => ({
    query: state.usersReducer.usersListQuery,
});
export default connect(mapStateToProps, {updateUsersQuery, getUsers})(withRouter(UsersActionBar));
