// @flow
import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {Poster} from "../model/Poster";
import AttachmentView from "../components/AttatchmentView/AttachmentView";

type Props = {
    posters: Poster[],
    currentPosterIndex: number,
    match: any,
};

const INTERVAL_DURATION_MILLIS = 5000;

const PresentationContent = (props: Props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    console.log(props.posters);
    useEffect(() => {
        let interval = null;
        if(currentIndex < props.posters.length -1) {
            interval = setInterval(() => {
                setCurrentIndex(index => index + 1);
            }, INTERVAL_DURATION_MILLIS);
        } else {
            interval = setInterval(() => {
                setCurrentIndex(0);
            }, INTERVAL_DURATION_MILLIS);
        }
        return () => clearInterval(interval);
    }, [currentIndex]);

    const height = window.innerHeight;
    const poster = props.posters[currentIndex];

    if(!poster)
        return null;

    return (
        <div>
            <div style={{}}>
                <AttachmentView attachmentLink={poster.fileUrl} filename={poster.fileName} height={height - 50} containerStyle={{}} presentationMode={true}/>
            </div>
        </div>
    );
};


export default withRouter(PresentationContent);
