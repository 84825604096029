// @flow
import React, {useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {connect} from "react-redux";
import {doLogin, getLoggedUser, updateLoginParams} from "../redux/modules/authModule";
import {Redirect, withRouter} from "react-router-dom";
import {AppUser} from "../model/AppUser";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

type Props = {
    email: string,
    password: string,
    user: AppUser,
    updateLoginParams: (username: string, password: string) => void,
    doLogin: () => void,
    getLoggedUser: () => void,
};

const Login = (props: Props) => {

    const classes = useStyles();

    useEffect(() => {
        props.getLoggedUser();
    },[]);

    if(props.user && props.user.id)
        return <Redirect to="/home/events"/>;

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(event) => {
                            props.updateLoginParams({email: event.target.value, password: props.password});
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(event) => {
                            props.updateLoginParams({email: props.email, password: event.target.value});
                        }}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => {
                            if (props.email && props.password) {
                                props.doLogin({email: props.email, password: props.password});
                            }
                        }}
                    >
                        Sign In
                    </Button>
                </form>
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    email: state.authReducer.email,
    password: state.authReducer.password,
    updateLoginParams: state.authReducer.updateLoginParams,
    doLogin: state.authReducer.doLogin,
    user: state.authReducer.currentUser
});

export default connect(mapStateToProps, {doLogin, updateLoginParams, getLoggedUser})(withRouter(Login));
