// @flow
import * as React from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import PostersActionBar from "../../../components/posters/PostersActionBar";
import PostersTable from "../../../components/posters/PostersTable";
import {getPosters} from "../../../redux/modules/postersModule";

type Props = {
    getPosters: () => void;
};
type State = {

};

class Posters extends React.Component<Props, State> {

    componentDidMount(): void {
        this.props.getPosters();
    }

    render() {
        return (
            <Grid container direction={'column'} style={{
                padding: 20
            }}>
                <div style={{
                    marginBottom: 20,
                    fontSize: 20,
                    fontWeight: 200
                }}>
                    POSTERS
                </div>
                <PostersActionBar/>
                <PostersTable/>
            </Grid>
        );
    };
}

export default connect(null, {getPosters})(Posters);
