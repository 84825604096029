import {Api} from "../../services/api";
import {Poster} from "../../model/Poster";
import {Event} from "../../model/Event";
import {DateTime} from "luxon";

const GET_POSTER_ACTIONS = 'GET_POSTER_ACTIONS';
const UPDATE_POSTER_ACTIONS_QUERY = 'UPDATE_POSTER_ACTIONS_QUERY';
const COUNT_POSTER_ACTIONS = 'COUNT_POSTER_ACTIONS';

const API_PATH = '/PosterActions';

const initState = {
    posterId: '',
    eventId: '',
    from: null,
    to: null,
    posterActionsList: [],
    posterActionsCount: 0,
    loading: false,
};

type Action = {
    type: string,
    payload: any
};

//NON DISPATCH
export const savePosterAction = async(poster: Poster, event: Event) => {
    try {
        await Api.instance.post(API_PATH, {
            posterId: poster.id,
            eventId: event.id,
            posterName: poster.name,
            eventName: event.name,
            action: 'POSTER_ACCESS'
        });
    } catch {
    }
};

const composeFilter = (reducer: any) => {
    const eventId = reducer.eventId;
    const posterId = reducer.posterId;
    const from = reducer.from;
    const to = reducer.to;

    let filter;
    let queries = [];

    if(eventId || posterId || from || to) {
        if(eventId)
            queries.push({eventId});
        if(posterId)
            queries.push({posterId});
        if(from)
            queries.push({createdAt: {gte: from}});
        if(to)
            queries.push({createdAt: {lte: to}});

        filter = {where: {and: queries}};
    }
    return filter;
};

export const countPosterActions = () => async (dispatch, getState) => {
    const {posterActionsReducer} = getState();
    const filter = composeFilter(posterActionsReducer);
    try {
        const queryUrl = filter ? `?filter=${JSON.stringify(filter)}` : '';
        const response = await Api.instance.get(API_PATH + '/count' + queryUrl);
        dispatch({
            type: COUNT_POSTER_ACTIONS,
            payload: response.data.count
        });
    } catch (e) {
        console.log(e);
    }
};

export const getPosterActions = () => async (dispatch, getState) => {
    const {posterActionsReducer} = getState();
    const filter = composeFilter(posterActionsReducer);

    try {
        const queryUrl = filter ? `?filter=${JSON.stringify(filter)}` : '';
        const response = await Api.instance.get(API_PATH + queryUrl);
        dispatch({
            type: GET_POSTER_ACTIONS,
            payload: response.data
        });
    } catch (e) {
        console.log(e);
    }
};

export const updatePosterActionsQuery = (query: {eventId: string, posterId: string, from: DateTime, to: DateTime}) => async (dispatch) => {
    dispatch({
        type: UPDATE_POSTER_ACTIONS_QUERY,
        payload: query
    });
};

export const posterActionsReducer = (state = initState, action: Action) => {
    switch (action.type) {
        case GET_POSTER_ACTIONS:
            return {
                ...state,
                posterActionsList: action.payload
            };
        case UPDATE_POSTER_ACTIONS_QUERY:
            return {
                ...state,
                posterId: action.payload.posterId,
                eventId: action.payload.eventId,
                from: action.payload.from,
                to: action.payload.to,
            };
        case COUNT_POSTER_ACTIONS:
            return {
                ...state,
                posterActionsCount: action.payload
            };
        default:
            return state;
    }
};
