export  const getFileExtension = (filename: string) => {
    const idx = filename.lastIndexOf('.');
    return (idx < 1) ? "" : filename.substr(idx + 1);
};

export const removeFileExtension = (filename: string) => {
    const idx = filename.lastIndexOf('.');
    return (idx < 1) ? filename : filename.substr(0, idx);
};


export const imageExtensions : string[] = ['jpg','jpeg','png'];

export const powerPointExtensions = ['pptx'];

export const sanitizeFilename = (filename: string) => {

    const extension = getFileExtension(filename);
    const nameWithoutExtension = removeFileExtension(filename);
    const sanitized = nameWithoutExtension.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    return `${sanitized}.${extension}`;
};
