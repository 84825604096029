// @flow
import * as React from 'react';
import {getFileExtension, imageExtensions, powerPointExtensions} from "../../generalUtils/generalUtils";
import PdfView from "./PdfView";
import Grid from "@material-ui/core/Grid";
import {withRouter} from "react-router-dom";
import printJS from "print-js";
import {BASE_URL} from "../../configs";

type Props = {
    attachmentLink: string,
    filename: string,
    width: number,
    height: number,
    containerStyle: any,
    printable: boolean,
    presentationMode: boolean
};



const AttachmentView = (props: Props) => {

    const simpleBar = (<Grid container direction={'row'}
                             justify={'center'}
                             alignContent={'center'}
                             style={{
                                 position: 'fixed',
                                 bottom: 0,
                                 left: 0,
                                 right: 0,
                                 height: 50,
                                 background: 'black',
                             }}>

        <div style={{
            background: 'white',
            borderRadius: 10,
            cursor:'pointer',
            padding: 10,
            marginTop: 10,
            marginBottom: 10,
            marginRight: 10
        }} onClick={() => {
            props.history.goBack();
        }}>
            Cerrar
        </div>

        {props.printable ? <div style={{
            background: 'white',
            borderRadius: 10,
            cursor:'pointer',
            padding: 10,
            margin: 10
        }} onClick={() => {
            if(props.attachmentLink)
                printJS(BASE_URL + props.attachmentLink, 'image');
        }}>
            Imprimir
        </div> : null}


    </Grid>);

    if(!props.filename || !props.attachmentLink)
        return (
            <div style={{padding: 20, background: "#eeeeee", minWidth: 100, textAlign: 'center'}}>
                Sin archivo
                {simpleBar}
            </div>
        );

    const extension = getFileExtension(props.filename).toLowerCase();
    const url = BASE_URL + props.attachmentLink;

    if(!extension)
        return (
            <div style={props.containerStyle}>
                Error al detectar la extensión de {props.filename}
                {simpleBar}
            </div>

        );

    if(imageExtensions.indexOf(extension) >= 0)
        return (
            <div style={{...props.containerStyle, background: 'black'}}>
                <img src={url} alt=""
                     style={{
                         width: '100%',
                         height: 'calc(100vh - 50px)',
                         objectFit: 'contain'
                     }}
                />
                {simpleBar}
            </div>);

    if(extension === 'pdf')
        return (
            <PdfView fileUrl={url} height={props.height} presentationMode={props.presentationMode} printable={props.printable}/>);

    return (
        <div>
            <div>ARCHIVO NO SOPORTADO .{extension}</div>
            <div>ARCHIVOS SOPORTADOS</div>
            <div>IMAGENES:</div>
            {imageExtensions.map((ext) => (<div>{ext}</div>))}
            <div>PDF:</div>
            <div>.pdf</div>
            <div>POWERPOINT:</div>
            {powerPointExtensions.map((ext) => (<div>{ext}</div>))}
            {simpleBar}
        </div>
    );
};

export default withRouter(AttachmentView);
