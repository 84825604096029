import { AppUser } from "../../model/AppUser";
import { Event } from "../../model/Event";
import { Api } from "../../services/api";
import { goBack } from "react-router-redux";
import {fetchEventUsers} from "./eventsModule";

export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const GET_USER_EVENTS = "GET_USER_EVENTS";
export const ADD_USER_EVENT = "ADD_USER_EVENT";
export const UPDATE_USER_EVENT_PARAMS = "UPDATE_USER_EVENT_PARAMS";
export const GET_USER_ROLES = "GET_USER_ROLES";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_QUERY = "UPDATE_USER_QUERY";
export const UPDATE_USER_PARAMS = "UPDATE_USER_PARAMS";
export const TOGGLE_USER_DELETE_CONFIRMATION =
  "TOGGLE_USER_DELETE_CONFIRMATION";
export const CHANGE_LOADING_STATE = "CHANGE_LOADING_STATE";

const API_PATH = "/AppUsers";
const ROLE_PATH = "/Roles";
const RELATED_EVENTS_PATH = "/events";

const initState = {
  usersListQuery: "",
  eventId: "",
  usersList: [],
  userRoles: [],
  showDeleteConfirmation: false,
  loading: false,
  selectedUser: new AppUser(),
  selectedUserEvents: [],
  selectedUserEventParams: new Event()
};

type Action = {
  type: string;
  payload: any;
};

export const toggleUserDeleteDialog = open => dispatch => {
  dispatch({
    type: TOGGLE_USER_DELETE_CONFIRMATION,
    payload: open
  });
};

export const deleteUser = () => async (dispatch, getState) => {
  const { usersReducer } = getState();
  dispatch({
    type: CHANGE_LOADING_STATE,
    payload: true
  });
  try {
    await Api.instance.delete(API_PATH + "/" + usersReducer.selectedUser.id);
    dispatch({
      type: TOGGLE_USER_DELETE_CONFIRMATION,
      payload: false
    });
    dispatch({
      type: UPDATE_USER,
      payload: {}
    });
    dispatch(goBack());
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: CHANGE_LOADING_STATE,
    payload: false
  });
};

export const updateUserParams = user => dispatch => {
  dispatch({
    type: UPDATE_USER_PARAMS,
    payload: user
  });
};

export const updateUser = (eventId) => async (dispatch, getState) => {
  const { usersReducer } = getState();
  dispatch({
    type: CHANGE_LOADING_STATE,
    payload: true
  });
  try {
    let response;
    response = await Api.instance.post(
      API_PATH + "/userUpsert",
      usersReducer.selectedUser
    );
    if(response.data.user.id && !usersReducer.selectedUser.id && eventId)
      await apiAddUserEvent(response.data.user.id, eventId);

    dispatch({
      type: UPDATE_USER,
      payload: response.data
    });
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: CHANGE_LOADING_STATE,
    payload: false
  });
};

export const updateUsersQuery = query => dispatch => {
  dispatch({
    type: UPDATE_USER_QUERY,
    payload: query
  });
};

export const getUserRoles = () => async (dispatch, getState) => {
  const {authReducer} = getState();

  const currentUser = authReducer.currentUser;

  try {
    let data = [];
    if(currentUser.role === 'admin') {
      const response = await Api.instance.get(ROLE_PATH);
      data = response.data;
    } else {
      data = [{
        id: 'posterAdmin',
        name: 'posterAdmin'
      }];
    }

    dispatch({
      type: GET_USER_ROLES,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUsers = (query, eventId) => async (dispatch, getState) => {
  const {authReducer} = getState();

  if(authReducer.currentUser.role !== 'admin' && !eventId)
    dispatch({
      type: GET_USERS,
      payload: []
    });

  try {
    let users = [];

    if(eventId) {
      users = await fetchEventUsers(eventId);
    } else {
      const filter = query
          ? { where: { email: { ilike: `${query}%25` } } }
          : null;
      const queryUrl = query ? `?filter=${JSON.stringify(filter)}` : "";
      const response = await Api.instance.get(API_PATH + queryUrl);
      users = response.data;
    }

    dispatch({
      type: GET_USERS,
      payload: users
    });
  } catch (e) {
    console.log(e);
  }
};

export const getUser = id => async dispatch => {
  if (id === "new") {
    return dispatch({
      type: GET_USER,
      payload: {
        role: 'posterAdmin'
      }
    });
  }

  try {
    const response = await Api.instance.get(API_PATH + `/${id}`);
    return dispatch({
      type: GET_USER,
      payload: { ...response.data }
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateUserEventParams = event => dispatch => {
  console.log(event);
  dispatch({
    type: UPDATE_USER_EVENT_PARAMS,
    payload: event
  });
};

export const fetchUserEvents = async (id) => {
  try {
    const response = await Api.instance.get(
        API_PATH + `/${id}` + RELATED_EVENTS_PATH
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getUserEvents = id => async dispatch => {
  try {
    const data = await fetchUserEvents(id);
    return dispatch({
      type: GET_USER_EVENTS,
      payload: [ ...data ]
    });
  } catch (e) {
    console.log(e);
  }
};

const apiAddUserEvent = async (userId, eventId) => {
  await Api.instance.put(
      API_PATH + `/${userId}` + RELATED_EVENTS_PATH + `/rel/${eventId}`
  );
  const response = await Api.instance.get(
      API_PATH + `/${userId}` + RELATED_EVENTS_PATH
  );
  return response.data;
};

export const addUserEvent = () => async (dispatch, getState) => {
  const { usersReducer } = getState();
  const user = usersReducer.selectedUser;
  const event = usersReducer.selectedUserEventParams;
  try {
    const data = await apiAddUserEvent(user.id, event.id);
    return dispatch({
      type: GET_USER_EVENTS,
      payload: [...data ]
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteUserEvent = (event) => async (dispatch, getState) => {
    const { usersReducer } = getState();
    const user = usersReducer.selectedUser;
    try {
        await Api.instance.delete(
            API_PATH + `/${user.id}` + RELATED_EVENTS_PATH + `/rel/${event.id}`
        );
        const response = await Api.instance.get(
            API_PATH + `/${user.id}` + RELATED_EVENTS_PATH
        );
        return dispatch({
            type: GET_USER_EVENTS,
            payload: [ ...response.data ]
        });
    } catch (e) {
        console.log(e);
    }
};

export const usersReducer = (state = initState, action: Action) => {
  switch (action.type) {
    case GET_USERS:
      if (action.payload) {
        return {
          ...state,
          usersList: action.payload
        };
      } else {
        return {
          ...state
        };
      }
    case GET_USER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case CHANGE_LOADING_STATE:
      return {
        ...state,
        loading: action.payload
      };
    case GET_USER_ROLES:
      return {
        ...state,
        userRoles: action.payload
      };
    case GET_USER_EVENTS:
      return {
        ...state,
        selectedUserEvents: action.payload
      };
    case ADD_USER_EVENT:
      return {
        ...state,
        selectedUserEvents: action.payload
      };
    case UPDATE_USER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case UPDATE_USER_EVENT_PARAMS:
      return {
        ...state,
        selectedUserEventParams: {...action.payload}
      };
    case UPDATE_USER_PARAMS:
      return {
        ...state,
        selectedUser: { ...action.payload }
      };
    case TOGGLE_USER_DELETE_CONFIRMATION:
      return {
        ...state,
        showDeleteConfirmation: action.payload
      };
    case UPDATE_USER_QUERY:
      return {
        ...state,
        usersListQuery: action.payload
      };
    default:
      return state;
  }
};
