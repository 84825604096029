import {DateTime} from "luxon";

export class Poster {
    id: number;
    eventId: number;
    categoryId: number;
    order: number;
    name: string;
    authors: string;
    code: string;
    workshopLabel: string;
    workshop: string;
    fileUrl: string;
    fileName: string;
    createdAt: DateTime;
    updatedAt: DateTime;
}
