// @flow
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Event } from "../../../model/Event";
import {
  deleteEvent,
  getEvent,
  toggleEventDeleteDialog,
  updateEvent,
  updateEventTemplateFile,
  updateEventParams,
  uploadEventTemplateFile,
  updateEventBannerFile,
  uploadEventBannerFile
} from "../../../redux/modules/eventsModule";
import {KeyboardDatePicker} from "@material-ui/pickers";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {
  clearCategories,
  getCategories
} from "../../../redux/modules/categoriesModule";
import CategoriesTable from "../../../components/categories/CategoriesTable";
import PostersTable from "../../../components/posters/PostersTable";
import { getPostersFromEvent } from "../../../redux/modules/postersModule";
import { ChromePicker } from "react-color";
import { AppUser } from "../../../model/AppUser";
import Users from "../Users/Users";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const formFieldStyles = {
  margin: "10px 10px 30px 10px"
};

type Props = {
  match: any,
  event: Event,
  currentUser: AppUser,
  showDeleteConfirmation: boolean,
  getEvent: (id: string) => void,
  updateEvent: (event: Event) => void,
  deleteEvent: () => void,
  clearCategories: () => void,
  getCategories: (id: number) => void,
  toggleEventDeleteDialog: (open: boolean) => void,
  updateEventParams: (event: Event) => void,
  updateEventTemplateFile: (file: any) => void,
  uploadEventTemplateFile: (event: Event, file: any) => void,
  updateEventBannerFile: (file: any) => void,
  uploadEventBannerFile: (event: Event, file: any) => void,
  getPostersFromEvent: () => void
};

const EventDetails = (props: Props) => {
  const [showTitleColor, setShowTitleColor] = useState(false);
  const [showAuthorsColor, setShowAuthorsColor] = useState(false);
  const [showWorkshopColor, setShowWorkshopColor] = useState(false);

  useEffect(() => {
    props.updateEventParams({});
    props.clearCategories();
    props.getEvent(props.match.params.id);
    props.getCategories(props.match.params.id);
    props.getPostersFromEvent(props.match.params.id);
  }, []);

  if (!props.currentUser) {
    return <Redirect to={"/"} />;
  }

  if (!props.event || props.loading)
    return (
      <Grid
        container
        direction={"row"}
        justify={"center"}
        style={{ margin: 20 }}
      >
        <CircularProgress />
      </Grid>
    );

  const showEventDetails =
    props.currentUser.role === "admin" ||
    props.currentUser.role === "eventAdmin";

  return (
    <Grid direction={"column"}>
      <Grid container direction={"row"} wrap={"wrap"} justify={"center"}>
        {showEventDetails ? (
          <Grid
            item
            xs={12}
            md={7}
            style={{
              padding: 20
            }}
          >
            <Paper
              style={{
                padding: "20px 40px 40px 40px "
              }}
            >
              <Grid container direction={"column"} alignItems={"flex-start"}>
                <div style={{ fontSize: 25, fontWeight: 200 }}>
                  {props.event.id
                    ? `Evento: ${props.event.name}`
                    : "Nuevo Evento"}
                </div>
                <Grid
                  container
                  direction={"row"}
                  wrap={"wrap"}
                  justify={"space-around"}
                  alignItems={"flex-start"}
                  style={{
                    marginTop: 20
                  }}
                >


                  <Grid
                    container
                    item
                    sm={12}
                    md={6}
                    direction={"column"}
                    style={{ paddingRight: 15 }}
                  >
                      <FormControlLabel
                          control={
                              <Checkbox
                                  checked={props.event.isActive}
                                  onChange={event => {
                                      const data = { ...props.event };
                                      data.isActive = event.target.checked;
                                      props.updateEventParams(data);
                                  }}
                                  color="primary"
                              />
                          }
                          label="Activo"
                      />
                    <TextField
                      style={formFieldStyles}
                      id="name"
                      label="Nombre"
                      value={props.event.name || ""}
                      onChange={event => {
                        const data = { ...props.event };
                        data.name = event.target.value;
                        props.updateEventParams(data);
                      }}
                    />

                    <TextField
                      style={formFieldStyles}
                      id="eventDate"
                      label="Fecha del evento"
                      value={props.event.eventDate || ""}
                      onChange={event => {
                        const data = { ...props.event };
                        data.eventDate = event.target.value;
                        props.updateEventParams(data);
                      }}
                    />

                    <TextField
                      style={formFieldStyles}
                      id="website"
                      label="Pagina web"
                      value={props.event.website || ""}
                      onChange={event => {
                        const data = { ...props.event };
                        data.website = event.target.value;
                        props.updateEventParams(data);
                      }}
                    />

                    <KeyboardDatePicker
                      style={formFieldStyles}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="end-date"
                      label="Fecha de finalización"
                      value={props.event.eventEndDate}
                      onChange={date => {
                        const data = { ...props.event };
                        data.eventEndDate = date;
                        props.updateEventParams(data);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />

                    <KeyboardDatePicker
                      style={formFieldStyles}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="end-date"
                      label="Inicio recepción de posters"
                      value={props.event.posterReceptionStartDate}
                      onChange={date => {
                        const data = { ...props.event };
                        data.posterReceptionStartDate = date;
                        props.updateEventParams(data);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />

                    <KeyboardDatePicker
                      style={formFieldStyles}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="end-date"
                      label="Fin recepción de posters"
                      value={props.event.posterReceptionEndDate}
                      onChange={date => {
                        const data = { ...props.event };
                        data.posterReceptionEndDate = date;
                        props.updateEventParams(data);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />

                      <FormControlLabel
                          control={
                              <Checkbox
                                  checked={props.event.printPosters || false}
                                  onChange={event => {
                                      const data = { ...props.event };
                                      data.printPosters = event.target.checked;
                                      props.updateEventParams(data);
                                  }}
                                  color="primary"
                              />
                          }
                          label="Imprimir Posters"
                      />
                      <FormControlLabel
                          control={
                              <Checkbox
                                  checked={props.event.showOrder || false}
                                  onChange={event => {
                                      const data = { ...props.event };
                                      data.showOrder = event.target.checked;
                                      props.updateEventParams(data);
                                  }}
                                  color="primary"
                              />
                          }
                          label="Mostrar orden"
                      />

                    <TextField
                      style={formFieldStyles}
                      id="description"
                      multiline={true}
                      label="Descripción"
                      value={props.event.description || ""}
                      onChange={event => {
                        const data = { ...props.event };
                        data.description = event.target.value;
                        props.updateEventParams(data);
                      }}
                    />
                  </Grid>

                  <Grid container direction={"column"} item sm={12} md={6}>
                    <Grid
                      container
                      item
                      direction={"column"}
                      alignContent={"center"}
                      xs
                      style={formFieldStyles}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 200,
                          textTransform: "uppercase",
                          marginBottom: 20
                        }}
                      >
                        Color del Título{" "}
                        <div
                          style={{
                            display: "inline-block",
                            width: 10,
                            height: 10,
                            borderRadius: 100,
                            background: props.event.titleColor || "grey"
                          }}
                        />
                      </div>
                      {showTitleColor ? (
                        <ChromePicker
                          color={props.event.titleColor || ""}
                          onChangeComplete={color => {
                            const data = { ...props.event };
                            data.titleColor = color.hex;
                            props.updateEventParams(data);
                          }}
                        />
                      ) : null}
                      <div>
                        <Button
                          variant={"contained"}
                          color={"primary"}
                          style={{
                            marginTop: 10
                          }}
                          onClick={() => {
                            setShowTitleColor(!showTitleColor);
                          }}
                        >
                          {showTitleColor
                            ? "Ocultar Selector"
                            : "Mostrar Selector"}
                        </Button>
                      </div>
                    </Grid>
                    <Grid
                      container
                      item
                      direction={"column"}
                      xs
                      alignContent={"center"}
                      style={formFieldStyles}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 200,
                          textTransform: "uppercase",
                          marginBottom: 20
                        }}
                      >
                        Color de Autores{" "}
                        <div
                          style={{
                            display: "inline-block",
                            width: 10,
                            height: 10,
                            borderRadius: 100,
                            background: props.event.authorsColor || "grey"
                          }}
                        />
                      </div>
                      {showAuthorsColor ? (
                        <ChromePicker
                          color={props.event.authorsColor || ""}
                          onChangeComplete={color => {
                            const data = { ...props.event };
                            data.authorsColor = color.hex;
                            props.updateEventParams(data);
                          }}
                        />
                      ) : null}
                      <div>
                        <Button
                          variant={"contained"}
                          color={"primary"}
                          style={{
                            marginTop: 10
                          }}
                          onClick={() => {
                            setShowAuthorsColor(!showAuthorsColor);
                          }}
                        >
                          {showAuthorsColor
                            ? "Ocultar Selector"
                            : "Mostrar Selector"}
                        </Button>
                      </div>
                    </Grid>
                    <Grid
                      container
                      item
                      direction={"column"}
                      xs
                      alignItems={"center"}
                      style={formFieldStyles}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 200,
                          textTransform: "uppercase",
                          marginBottom: 20
                        }}
                      >
                        Color de centro de trabajo{" "}
                        <div
                          style={{
                            display: "inline-block",
                            width: 10,
                            height: 10,
                            borderRadius: 100,
                            background: props.event.workshopColor || "grey"
                          }}
                        />
                      </div>
                      {showWorkshopColor ? (
                        <ChromePicker
                          color={props.event.workshopColor || ""}
                          onChangeComplete={color => {
                            const data = { ...props.event };
                            data.workshopColor = color.hex;
                            props.updateEventParams(data);
                          }}
                        />
                      ) : null}
                      <div>
                        <Button
                          variant={"contained"}
                          color={"primary"}
                          style={{
                            marginTop: 10
                          }}
                          onClick={() => {
                            setShowWorkshopColor(!showWorkshopColor);
                          }}
                        >
                          {showWorkshopColor
                            ? "Ocultar Selector"
                            : "Mostrar Selector"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction={"row"}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    style={{
                      marginTop: 20,
                      marginRight: 10
                    }}
                    disabled={!props.event.name}
                    onClick={() => {
                      props.updateEvent(props.event);
                    }}
                  >
                    {props.event.id ? "Guardar" : "Crear"}
                  </Button>
                  {props.currentUser && props.currentUser.role === "admin" ? (
                    <Button
                      color={"secondary"}
                      style={{
                        marginTop: 20
                      }}
                      onClick={() => {
                        props.toggleEventDeleteDialog(true);
                      }}
                    >
                      Eliminar
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : null}
        {props.event.id && showEventDetails ? (
          <Grid
            item
            xs={12}
            md={5}
            container
            direction={"column"}
            style={{ padding: 20 }}
          >
            <Paper style={{ padding: "20px 40px 40px 40px", marginBottom: 20 }}>
              <Grid container direction={"column"} alignItems={"center"}>
                <Grid
                  container
                  direction={"row"}
                  justify={"center"}
                  style={{ fontSize: 20, marginBottom: 20 }}
                >
                  Plantilla de poster
                </Grid>
                <Grid
                  container
                  direction={"row"}
                  wrap={"wrap"}
                  justify={"space-around"}
                >
                  <Grid
                    container
                    item
                    direction={"column"}
                    xs
                    alignItems={"center"}
                  >
                    <div style={{ fontSize: 20, marginBottom: 10 }}>
                      {props.event.posterTemplateUrl
                        ? "Sustituir plantilla"
                        : "Subir nueva "}
                    </div>
                    <input
                      type="file"
                      name="file"
                      onChange={event => {
                        props.updateEventTemplateFile(event.target.files[0]);
                      }}
                    />

                    <Button
                      variant={"contained"}
                      color={"primary"}
                      style={{
                        marginTop: 20
                      }}
                      disabled={!props.event.name}
                      onClick={() => {
                        props.uploadEventTemplateFile(props.event);
                      }}r
                    >
                      Guardar Adjunto
                    </Button>
                  </Grid>
                  <Grid
                    container
                    item
                    direction={"column"}
                    xs
                    alignItems={"center"}
                  >
                    <div style={{ fontSize: 20, marginBottom: 10 }}>Actual</div>

                    <div>
                      {props.event.posterTemplateUrl ? (
                        <div style={{ marginTop: 10 }}>
                          <div
                            style={{
                              textDecoration: "none",
                              color: "black",
                              padding: 10,
                              background: "#eeeeee",
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              window.open(
                                props.event.posterTemplateUrl,
                                "_blank"
                              );
                            }}
                          >
                            Archivo: {props.event.posterTemplateFileName}
                          </div>
                        </div>
                      ) : (
                        <div>El evento no tiene plantilla.</div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper style={{ padding: "20px 40px 40px 40px", marginBottom: 20 }}>
              <Grid container direction={"column"} alignItems={"center"}>
                <Grid
                  container
                  direction={"row"}
                  justify={"center"}
                  style={{ fontSize: 20, marginBottom: 20 }}
                >
                  Banner de poster
                </Grid>
                <Grid
                  container
                  direction={"row"}
                  wrap={"wrap"}
                  justify={"space-around"}
                >
                  <Grid
                    container
                    item
                    direction={"column"}
                    xs
                    alignItems={"center"}
                  >
                    <div style={{ fontSize: 20, marginBottom: 10 }}>
                      {props.event.eventBannerUrl
                        ? "Sustituir banner"
                        : "Subir nuevo "}
                    </div>
                    <input
                      type="file"
                      name="file"
                      onChange={event => {
                        props.updateEventBannerFile(event.target.files[0]);
                      }}
                    />

                    <Button
                      variant={"contained"}
                      color={"primary"}
                      style={{
                        marginTop: 20
                      }}
                      disabled={!props.event.name}
                      onClick={() => {
                        props.uploadEventBannerFile(props.event);
                      }}
                    >
                      Guardar Adjunto
                    </Button>
                  </Grid>
                  <Grid
                    container
                    item
                    direction={"column"}
                    xs
                    alignItems={"center"}
                  >
                    <div style={{ fontSize: 20, marginBottom: 10 }}>Actual</div>

                    <div>
                      {props.event.eventBannerUrl ? (
                        <div style={{ marginTop: 10 }}>
                          <div
                            style={{
                              textDecoration: "none",
                              color: "black",
                              padding: 10,
                              background: "#eeeeee",
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              window.open(props.event.eventBannerUrl, "_blank");
                            }}
                          >
                            Archivo: {props.event.eventBannerFileName}
                          </div>
                        </div>
                      ) : (
                        <div>El evento no tiene banner.</div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <CategoriesTable />
          </Grid>
        ) : null}

        {props.event.id && showEventDetails ? (
          <Users eventId={props.event.id} />
        ) : null}

        {props.event.id ? (
          <Grid
            item
            xs={12}
            container
            direction={"column"}
            style={{ padding: 20 }}
          >
            <div
              style={{
                fontSize: 20,
                textTransform: "uppercase",
                fontWeight: 200,
                marginTop: 10,
                marginBottom: 10
              }}
            >
              Posters{" "}
              <Button
                style={{ display: "inline-block" }}
                color={"primary"}
                onClick={() => {
                  props.history.push(
                    `/home/event/${props.event.id}/poster/new`
                  );
                }}
              >
                Crear Nuevo
              </Button>
            </div>

            <PostersTable event={props.event} />
          </Grid>
        ) : null}
      </Grid>
      <Dialog
        open={props.showDeleteConfirmation}
        onClose={() => {
          props.toggleEventDeleteDialog(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Confirmación"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Deseas eliminar este evento?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.toggleEventDeleteDialog(false);
            }}
            color="primary"
          >
            cancelar
          </Button>
          <Button
            onClick={() => {
              props.deleteEvent();
            }}
            color="secondary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const mapStateToProps = state => ({
  currentUser: state.authReducer.currentUser,
  event: state.eventsReducer.selectedEvent,
  loading: state.eventsReducer.loading,
  showDeleteConfirmation: state.eventsReducer.showDeleteConfirmation
});

export default connect(
  mapStateToProps,
  {
    getEvent,
    updateEvent,
    updateEventParams,
    updateEventTemplateFile,
    uploadEventTemplateFile,
    updateEventBannerFile,
    uploadEventBannerFile,
    clearCategories,
    getCategories,
    deleteEvent,
    toggleEventDeleteDialog,
    getPostersFromEvent
  }
)(withRouter(EventDetails));
