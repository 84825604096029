// @flow
import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import EventsActionBar from "../../../components/events/EventsActionBar";
import EventsTable from "../../../components/events/EventsTable";
import {getEvents} from "../../../redux/modules/eventsModule";
import {AppUser} from "../../../model/AppUser";

type Props = {
    getEvents: () => void,
    user: AppUser,
};


export const Events = (props: Props) => {

    useEffect( () => {
        props.getEvents();
    } , []);

    if(!props.user)
        return null;

    return (
        <Grid container direction={'column'} style={{
            padding: 20
        }}>
            <div style={{
                marginBottom: 20,
                fontSize: 20,
                fontWeight: 200
            }}>
                EVENTOS
            </div>
            {props.user.role === 'admin' ? <EventsActionBar/> : null}
            <EventsTable/>
        </Grid>
    );
};

const mapStateToProps = state => ({
    user: state.authReducer.currentUser
});

export default connect(mapStateToProps, {getEvents})(Events);
