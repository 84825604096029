// @flow
import React, {useState} from 'react';
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {UserAction} from "../../model/UserAction";
import TablePagination from "@material-ui/core/TablePagination";

type Props = {
    userActions: UserAction[],
};

const UsersTable = (props: Props) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    if(!props.userActions)
        return (
            <Grid container direction={"column"}>
                <CircularProgress/>
            </Grid>
        );

    const records = [...props.userActions].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Grid container direction={"column"}>
            <Paper style={{
                marginBottom: 10
            }}>
                <Grid direction={'row'} container style={{padding: 10}}>
                    <div>Número: {props.userActions.length}</div>
                </Grid>
            </Paper>
            <Paper style={{}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={"left"}>Id</TableCell>
                            <TableCell align={"left"}>Email Usuario</TableCell>
                            <TableCell align={"left"}>Fecha</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map(action => (
                            <TableRow key={action.id} style={{cursor: 'pointer'}}>
                                <TableCell align={"left"}>
                                    {action.id}
                                </TableCell>
                                <TableCell align={"left"}>{action.userEmail}</TableCell>
                                <TableCell align={"left"}>{action.createdAt}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={props.userActions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={(event, newPage) => {
                        setPage(newPage);
                    }}
                    onChangeRowsPerPage={(event) => {
                        setRowsPerPage(+event.target.value);
                        setPage(0);
                    }}
                />
            </Paper>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    userActions: state.userActionsReducer.userActionsList,
});

export default connect(mapStateToProps, null)(withRouter(UsersTable));
