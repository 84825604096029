// @flow
import * as React from 'react';
import {connect} from "react-redux";
import Select from 'react-select';
import {Poster} from "../../model/Poster";

type Props = {
    posters: Poster[],
    currentPoster: Poster,
    currentPosterId: number,
    onPosterSelected: (posterName: string) => void
};
const PostersSelector = (props: Props) => {

    if(!props.posters)
        return null;

    let currentPoster = props.currentPoster;

    if(props.currentPosterId)
        props.posters.forEach((p) => {
            if(p.id === props.currentEventId)
                currentPoster = p;
        });

    return (
            <Select
                styles={{control: styles => ({ ...styles, width: 200 })}}
                defaultValue={currentPoster ? currentPoster.name || '' : ''}
                label="Posters"
                onChange={(data) => {
                    props.onPosterSelected(data.value);
                }}
                options={props.posters.map((poster) => ({value: poster.name, label: poster.name}))}
            />
    );
};


export default connect(null, null)(PostersSelector);
