import {fetchEvent} from "./eventsModule";
import {Category} from "../../model/Category";
import {fetchCategories} from "./categoriesModule";
import {Poster} from "../../model/Poster";
import {Event} from "../../model/Event";
import {fetchPoster, fetchPostersFromCategory, fetchPostersFromEvent} from "./postersModule";
import {Api} from "../../services/api";
import {BASE_URL} from "../../configs";

const fetchPosterAndAttachment = async(posterId: String) => {
    const poster = (await fetchPoster(posterId)).data;
    if(poster.fileUrl)
        await Api.instance.get(BASE_URL + poster.fileUrl);
};

export const syncData = async (eventId: string): boolean => {
    try {
        let posters: Poster[] = [];
        const event: Event = (await fetchEvent(eventId)).data;
        const categories: Category[] = (await fetchCategories(event.id)).data;
        if(categories && categories.length > 0) {
            for(let i  = 0; i < categories.length; i++) {
                console.log(categories[i].id);
                const buffer = (await fetchPostersFromCategory(categories[i].id.toString())).data;
                posters.push(...buffer);
            }
        } else {
            posters = (await fetchPostersFromEvent(eventId)).data;
        }
        if(posters && posters.length > 0) {
            for(let i  = 0; i < posters.length; i++) {
                await fetchPosterAndAttachment(posters[i].id);
            }
        }

        return true;

    } catch (e) {
        console.log(e);
        return false;
    }
};
