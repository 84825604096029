// @flow
import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Poster} from "../model/Poster";
import {getPostersFromEvent} from "../redux/modules/postersModule";
import PresentationContent from './presentationContent';

type Props = {
    posters: Poster[],
    currentPosterIndex: number,
    match: any,
    getPostersFromEvent: (id: string) => {},
};

const shuffle = (arr) => {
    let i,
        j,
        temp;
    for (i = arr.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
    }
    return arr;
};


const PresentationSection = (props: Props) => {

    useEffect(() => {
        props.getPostersFromEvent(props.match.params.id);
    }, []);

    if (!props.posters || props.posters.length === 0)
        return (
            <Grid
                container
                direction={"row"}
                justify={"center"}
                style={{ margin: 20 }}
            >
                <CircularProgress />
            </Grid>
        );

    return (
        <PresentationContent posters={shuffle(props.posters)}/>
    );
};


const mapStateToProps = (state) => ({
    posters: state.postersReducer.posterFromEventList,
    currentPosterIndex: state.postersReducer.posterPresentationPosterIndex
});


export default connect(mapStateToProps, {getPostersFromEvent})(withRouter(PresentationSection))
