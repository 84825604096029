import axios from "axios";
import {BASE_URL} from "../configs";

const TOKEN_KEY = 'TOKEN_KEY';

export class Api {
  static instance;
  static initInstance = () => {
    if(localStorage.getItem(TOKEN_KEY)) {
      Api.instance = axios.create({
        headers: {Authorization: localStorage.getItem(TOKEN_KEY)},
        baseURL: BASE_URL
      });
    } else {
      Api.instance = axios.create({
        headers: {},
        baseURL: BASE_URL
      });
    }
  };

  static setToken = (token: string) => {
    if (!Api.instance) Api.initInstance();
    localStorage.setItem(TOKEN_KEY, token);
    Api.instance = axios.create({
      headers: {Authorization: token},
      baseURL: BASE_URL
    });
  };

  static removeToken = () => {
    if (!Api.instance) Api.initInstance();
    Api.instance.headers.token = "";
  };
}
