// @flow
import * as React from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {getEvents} from "../../../redux/modules/eventsModule";
import {getPostersFromEvent} from "../../../redux/modules/postersModule";
import {countPosterActions, getPosterActions} from "../../../redux/modules/posterActionsModule";
import PosterActionsActionBar from "../../../components/PosterActions/PosterActionsActionBar";
import PosterActionsTable from "../../../components/PosterActions/PosterActionsTable";

type Props = {
    getPosterActions: () => void,
    countPosterActions: () => void,
    getEvents: () => void
};

type State = {

};

class PosterActions extends React.Component<Props, State> {

    componentDidMount(): void {
        this.props.getPosterActions();
        this.props.countPosterActions();
        this.props.getEvents();
    }

    render() {
        return (
            <Grid container direction={'column'} style={{
                padding: 20
            }}>
                <div style={{
                    marginBottom: 20,
                    fontSize: 20,
                    fontWeight: 200
                }}>
                    POSTERS
                </div>
                <PosterActionsActionBar/>
                <PosterActionsTable/>
            </Grid>
        );
    };
}

export default connect(null, {getPosterActions, countPosterActions, getEvents, getPostersFromEvent})(PosterActions);
