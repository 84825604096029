import React from "react";
import "./App.css";
import Dashboard from "./dashboard/Dashboard";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./login/Login";
import { Api } from "./services/api";
import PublicEventSection from "./publicSection/publicEventSection";
import PostersSection from "./publicSection/posters/PostersSection";
import PosterDetailsSection from "./publicSection/posters/PosterDetailsSection";
import PresentationSection from "./publicSection/presentationSection";

function App() {
  Api.initInstance();

  return (
    <Router>
      <Route exact path="/" component={Login} />
      <Route path="/home" component={Dashboard} />
      <Route exact path="/public/event/:id" component={PublicEventSection} />
      <Route exact path="/public/event/:id/category/:categoryId" component={PostersSection} />
        <Route exact path="/public/event/:id/poster/:posterId" component={PosterDetailsSection} />
        <Route exact path="/public/event/:id/presentation" component={PresentationSection} />
    </Router>
  );
}

export default App;
