// @flow
import React,{useEffect} from 'react';
import {getUsers} from "../../../redux/modules/usersModule";
import UsersTable from "../../../components/users/UsersTable";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import UsersActionBar from "../../../components/users/UsersActionBar";

type Props = {
    getUsers: (query: string, eventId: string) => void;
    eventId: string
};

const Users = (props: Props) => {

    useEffect(() => {
        props.getUsers(null, props.eventId);
    }, []);

    return (
        <Grid container direction={'column'} style={{
            padding: 20
        }}>
            <div style={{
                marginBottom: 20,
                fontSize: 20,
                fontWeight: 200
            }}>
                USUARIOS
            </div>
            <UsersActionBar eventId={props.eventId}/>
            <UsersTable/>
        </Grid>
    );
};

export default connect(null, {getUsers})(Users);
