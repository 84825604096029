import {Event} from "../../model/Event";
import {Api} from "../../services/api";
import {BASE_URL} from "../../configs";
import {goBack} from "react-router-redux";
import {getFileExtension, removeFileExtension, sanitizeFilename} from "../../generalUtils/generalUtils";
import {DocConversionApi} from "../../services/docConversionApi";
import {fetchUserEvents} from "./usersModule";


export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENT = 'GET_EVENT';

export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_QUERY = 'UPDATE_EVENT_QUERY';
export const UPDATE_EVENT_PARAMS = 'UPDATE_EVENT_PARAMS';
export const UPDATE_EVENT_TEMPLATE_FILE = 'UPDATE_EVENT_TEMPLATE_FILE';
export const UPDATE_EVENT_BANNER_FILE = 'UPDATE_EVENT_BANNER_FILE';
export const DELETE_EVENT = 'DELETE_EVENT';
export const TOGGLE_EVENT_DELETE_CONFIRMATION = 'TOGGLE_EVENT_DELETE_CONFIRMATION';
export const CHANGE_LOADING_STATE = 'CHANGE_LOADING_STATE';

const API_PATH = '/Events';
const RELATED_USER_PATH = '/appUsers';
const FILES_PATH = '/Attachments';
const TEMPLATE_CONTAINER_PATH = '/posterTemplate';
const BANNER_CONTAINER_PATH = '/eventBanner';

const initState = {
    eventListQuery: '',
    eventsList: [],
    loading: false,
    showDeleteConfirmation: false,
    selectedEvent: new Event(),
    selectedTemplateFile: null,
    selectedBannerFile: null
};

type Action = {
    type: string,
    payload: any
};

export const fetchEventUsers = async (id) => {
    const response = await Api.instance.get(API_PATH + `/${id}${RELATED_USER_PATH}`);
    return response.data;
};

export const toggleEventDeleteDialog = (open) => (dispatch) => {
    dispatch({
        type: TOGGLE_EVENT_DELETE_CONFIRMATION,
        payload: open
    });
};

export const updateEventParams = (event) => (dispatch) => {
    dispatch({
        type: UPDATE_EVENT_PARAMS,
        payload: event
    });
};

export const updateEventTemplateFile = (file) => (dispatch) => {
    dispatch({
        type: UPDATE_EVENT_TEMPLATE_FILE,
        payload: file
    });
};

export const updateEventBannerFile = (file) => (dispatch) => {
    dispatch({
        type: UPDATE_EVENT_BANNER_FILE,
        payload: file
    });
};

export const deleteEvent = () => async (dispatch, getState) => {
    const {eventsReducer} = getState();
    dispatch({
        type: CHANGE_LOADING_STATE,
        payload: true
    });
    try {
        if(eventsReducer.selectedEvent.posterTemplateUrl && eventsReducer.selectedEvent.posterTemplateFileName)
            await Api.instance.delete(FILES_PATH + TEMPLATE_CONTAINER_PATH + '/files/' + eventsReducer.selectedEvent.posterTemplateFileName);
        await Api.instance.delete(API_PATH + '/' + eventsReducer.selectedEvent.id);
        dispatch({
            type: TOGGLE_EVENT_DELETE_CONFIRMATION,
            payload: false
        });
        dispatch({
            type: UPDATE_EVENT_PARAMS,
            payload: {}
        });
        dispatch(goBack());
    } catch (e) {
        console.log(e);
    }
    dispatch({
        type: CHANGE_LOADING_STATE,
        payload: false
    });
};

export const uploadEventTemplateFile = () => async (dispatch, getState) => {
    const {eventsReducer} = getState();
    const file = eventsReducer.selectedTemplateFile;
    const event = eventsReducer.selectedEvent;

    if(event.posterTemplateFileName)
        await Api.instance.delete(BASE_URL + FILES_PATH + TEMPLATE_CONTAINER_PATH + `/files/${event.posterTemplateFileName}`);

    if(getFileExtension(file.name) === 'pptx') {
        const convertedFile = await DocConversionApi.convertDocumentPptxToPdf(file);
        await uploadTemplateFile(convertedFile, removeFileExtension(file.name)+'.pdf', event, dispatch);
    } else {
        await uploadTemplateFile(file, file.name, event, dispatch);
    }
};

const uploadTemplateFile = async(file, fileName, event, dispatch) => {
    const data = new FormData();
    data.append('file', file, sanitizeFilename(file.name));
    try {
        const uploadResponse = await Api.instance.post(FILES_PATH + TEMPLATE_CONTAINER_PATH + '/upload', data, {});
        dispatch({
            type: UPDATE_EVENT_BANNER_FILE,
            payload: null
        });
        if(uploadResponse.data.result && uploadResponse.data.result.files && uploadResponse.data.result.files.file) {
            const file = uploadResponse.data.result.files.file[0];
            const newEvent: Event = {...event, posterTemplateFileName: file.name, posterTemplateUrl: BASE_URL + FILES_PATH + `/${file.container}/download/${file.name}`};
            try {
                let response;
                response = await Api.instance.post(API_PATH + '/replaceOrCreate', newEvent);

                dispatch({
                    type: UPDATE_EVENT,
                    payload: response.data
                });
            } catch (e) {
                console.log(e);
            }
        }
    } catch (e) {
        console.log(e);
    }
};


export const uploadEventBannerFile = () => async (dispatch, getState) => {
    const {eventsReducer} = getState();
    const file = eventsReducer.selectedBannerFile;
    const event = eventsReducer.selectedEvent;

    if(event.eventBannerFileName)
        await Api.instance.delete(BASE_URL + FILES_PATH + BANNER_CONTAINER_PATH + `/files/${event.eventBannerFileName}`);

    await uploadBannerFile(file, file.name, event, dispatch);
};

const uploadBannerFile = async(file, fileName, event, dispatch) => {
    const data = new FormData();
    data.append('file', file, sanitizeFilename(file.name));
    try {
        const uploadResponse = await Api.instance.post(FILES_PATH + BANNER_CONTAINER_PATH + '/upload', data, {});
        dispatch({
            type: UPDATE_EVENT_BANNER_FILE,
            payload: null
        });
        if(uploadResponse.data.result && uploadResponse.data.result.files && uploadResponse.data.result.files.file) {
            const file = uploadResponse.data.result.files.file[0];
            const newEvent: Event = {...event, eventBannerFileName: file.name, eventBannerUrl: BASE_URL + FILES_PATH + `/${file.container}/download/${file.name}`};
            try {
                let response;
                response = await Api.instance.post(API_PATH + '/replaceOrCreate', newEvent);

                dispatch({
                    type: UPDATE_EVENT,
                    payload: response.data
                });
            } catch (e) {
                console.log(e);
            }
        }
    } catch (e) {
        console.log(e);
    }
};


export const updateEvent = () => async (dispatch, getState) => {
    const {eventsReducer} = getState();
    dispatch({
        type: CHANGE_LOADING_STATE,
        payload: true
    });
    try {
        let response;
        response = await Api.instance.post(API_PATH + '/replaceOrCreate', eventsReducer.selectedEvent);

        dispatch({
            type: UPDATE_EVENT,
            payload: response.data
        });
    } catch (e) {
        console.log(e);
    }
    dispatch({
        type: CHANGE_LOADING_STATE,
        payload: false
    });
};

export const updateEventQuery = (query) => (dispatch) => {
    dispatch({
        type: UPDATE_EVENT_QUERY,
        payload: query
    });
};

export const getEvents = (query) => async (dispatch, getState) => {
    const {authReducer} = getState();
    const user = authReducer.currentUser;

    if(!user)
        return;

    try {
        let events = [];
        if(user.role === 'admin') {
            const filter = query ? {where: {name: {ilike: `${query}%25`}}} : null;
            const queryUrl = query ? `?filter=${JSON.stringify(filter)}` : '';
            const response = await Api.instance.get(API_PATH + queryUrl);
            events = response.data;
        } else {
            events = await fetchUserEvents(user.id);
        }
        dispatch({
            type: GET_EVENTS,
            payload: events
        });
    } catch (e) {
        console.log(e);
    }
};


export const fetchEvent = async (id) => {
    return await Api.instance.get(API_PATH + `/${id}`);
};

export const getEvent = (id) => async (dispatch) => {
    if(id === 'new') {
        return dispatch({
            type: GET_EVENT,
            payload: {}
        });
    }

    try {
        const response = await fetchEvent(id);
        return dispatch({
            type: GET_EVENT,
            payload: {...response.data}
        });
    } catch (e) {
        console.log(e);
    }
};


export const eventsReducer = (state = initState, action: Action) => {
    switch (action.type) {
        case GET_EVENTS:
            if(action.payload) {
                return {
                    ...state,
                    eventsList: action.payload
                };
            } else {
                return {
                    ...state,
                };
            }
        case GET_EVENT:
            return {
                ...state,
                selectedEvent: action.payload
            };
        case CHANGE_LOADING_STATE:
            return {
                ...state,
                loading: action.payload
            };
        case UPDATE_EVENT:
            return {
                ...state,
                selectedEvent: action.payload
            };
        case UPDATE_EVENT_PARAMS:
            return {
                ...state,
                selectedEvent: {...action.payload}
            };
        case UPDATE_EVENT_TEMPLATE_FILE:
            return {
                ...state,
                selectedTemplateFile: action.payload
            };
        case UPDATE_EVENT_BANNER_FILE:
            return {
                ...state,
                selectedBannerFile: action.payload
            };
        case UPDATE_EVENT_QUERY:
            return {
                ...state,
                eventListQuery: action.payload
            };
        case TOGGLE_EVENT_DELETE_CONFIRMATION:
            return {
                ...state,
                showDeleteConfirmation: action.payload
            };
        case DELETE_EVENT:
            return {
                ...state,
            };
        default:
            return state;
    }
};
