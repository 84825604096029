// @flow
import * as React from 'react';
import {connect} from "react-redux";
import {deleteCategory, getCategories, updateCategory} from "../../redux/modules/categoriesModule";
import MaterialTable from "material-table";
import {Category} from "../../model/Category";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

type Props = {
    categoriesList: Category[],
    updateCategory: (category: Category) => void,
    deleteCategory: (category: Category) => void
};

const columns =  [
    { title: 'Id', field: 'id', hidden: true },
    { title: 'Orden', field: 'order', type: 'numeric' },
    { title: 'Nombre', field: 'name' },
    { title: 'Descripción', field: 'description' },
];

const CategoriesTable = (props: Props) => {
    return (
        <MaterialTable
            title="Categorias"
            columns={columns}
            data={props.categoriesList}
            icons={{
                Clear: () => <Close style={{color: 'red'}}/>,
                Check: () => <Check style={{color: 'green'}}/>,
            }}
            editable={{
                onRowAdd: newData =>
                    new Promise(resolve => {
                        props.updateCategory(newData);
                        resolve();
                    }),
                onRowUpdate: (newData) =>
                    new Promise(resolve => {
                        props.updateCategory(newData);
                        resolve();
                    }),
                onRowDelete: oldData =>
                    new Promise(resolve => {
                        props.deleteCategory(oldData);
                        resolve();
                    }),
            }}
        />
    );
};

const mapStateToProps = (state) => ({
    categoriesList: state.categoriesReducer.categoriesList
});

export default connect(mapStateToProps, {getCategories, updateCategory, deleteCategory})(CategoriesTable);
