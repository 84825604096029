// @flow
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppUser } from "../../../model/AppUser";
import { CircularProgress } from "@material-ui/core";
import {
  deleteUser,
  getUser,
  getUserEvents,
  getUserRoles,
  toggleUserDeleteDialog,
  updateUser,
  updateUserParams
} from "../../../redux/modules/usersModule";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Role } from "../../../model/Role";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { getEvents } from "../../../redux/modules/eventsModule";
import UserEvents from "../../../components/users/UserEvents";

const formFieldStyles = {
  margin: 10
};

type Props = {
  match: any,
  loading: boolean,
  user: AppUser,
  userRoles: Role[],
  getUser: (id: string) => void,
  getUserRoles: () => void,
  getEvents: () => void,
  deleteUser: () => void,
  currentUser: AppUser,
  toggleUserDeleteDialog: (open: boolean) => void,
  updateUser: (user: AppUser) => void,
  updateUserParams: (user: AppUser) => void,
  getUserEvents: (id: string) => void
};
const UserDetails = (props: Props) => {
  useEffect(() => {
    const id = props.match.params.userId
      ? props.match.params.userId
      : props.match.params.id;
    props.updateUserParams({});
    props.getUser(id);
    if(id !== 'new')
        props.getUserEvents(id);
    props.getUserRoles();
    props.getEvents();
  }, []);

  if (!props.user || props.loading)
    return (
      <Grid
        container
        direction={"row"}
        justify={"center"}
        style={{ margin: 20 }}
      >
        <CircularProgress />
      </Grid>
    );

  return (
    <Grid container direction={"column"}>
      <Paper
        style={{
          margin: 20,
          padding: 20
        }}
      >
        <Grid container direction={"column"} alignItems={"center"}>
          <div style={{ fontSize: 20, fontWeight: 200 }}>
            {props.user.id ? props.user.username : "Nuevo Usuario"}
          </div>
          <Grid
            container
            direction={"row"}
            wrap={"wrap"}
            justify={"space-around"}
            alignItems={"center"}
            style={{
              marginTop: 20
            }}
          >
            <Grid item style={formFieldStyles}>
              <TextField
                id="name"
                label="Nombre"
                value={props.user.username || ""}
                onChange={event => {
                  const data = { ...props.user };
                  data.username = event.target.value;
                  props.updateUserParams(data);
                }}
              />
            </Grid>
            <Grid item style={formFieldStyles}>
              <TextField
                id="email"
                label="Email"
                value={props.user.email || ""}
                onChange={event => {
                  const data = { ...props.user };
                  data.email = event.target.value;
                  props.updateUserParams(data);
                }}
              />
            </Grid>
            <Grid item style={formFieldStyles}>
              <TextField
                id="password"
                label="Contraseña"
                type={"password"}
                value={props.user.password || ""}
                onChange={event => {
                  const data = { ...props.user };
                  data.password = event.target.value;
                  props.updateUserParams(data);
                }}
              />
            </Grid>
            {props.userRoles ? (
              <FormControl id="role">
                <InputLabel htmlFor="role">Rol</InputLabel>
                <Select
                  value={props.user.role || ""}
                  onChange={event => {
                    const data = { ...props.user };
                    data.role = event.target.value;
                    props.updateUserParams(data);
                  }}
                  inputProps={{
                    name: "Rol",
                    id: "role"
                  }}
                >
                  {props.userRoles.map(role => (
                    <MenuItem key={role.id} value={role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Button
            variant={"contained"}
            color={"primary"}
            style={{
              marginTop: 20
            }}
            disabled={!props.user.email}
            onClick={async () => {
              await props.updateUser(
                props.match.params.userId ? props.match.params.id : null
              );
              if (!props.user.id) {
                props.history.goBack();
              }
            }}
          >
              {props.currentUser && props.currentUser.id ? 'Guardar' : 'Guardar cambios'}
          </Button>
          {!props.currentUser ||
          props.currentUser.role !== "admin" ||
          !props.user ||
          !props.user.id ? null : (
            <Button
              color={"secondary"}
              style={{
                marginTop: 20
              }}
              onClick={() => {
                props.toggleUserDeleteDialog(true);
              }}
            >
              Eliminar
            </Button>
          )}
        </Grid>

        <Dialog
          open={props.showDeleteConfirmation}
          onClose={() => {
            props.toggleUserDeleteDialog(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Confirmación"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Deseas eliminar este usuario?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.toggleUserDeleteDialog(false);
              }}
              color="primary"
            >
              cancelar
            </Button>
            <Button
              onClick={() => {
                props.deleteUser();
              }}
              color="secondary"
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
      <Paper
        style={{
          margin: 20,
          padding: 20
        }}
      >
        {props.user && props.user.id ? <UserEvents /> : null}
      </Paper>
    </Grid>
  );
};

const mapStateToProps = state => ({
  user: state.usersReducer.selectedUser,
  currentUser: state.authReducer.currentUser,
  userRoles: state.usersReducer.userRoles,
  loading: state.usersReducer.loading,
  showDeleteConfirmation: state.usersReducer.showDeleteConfirmation,
  events: state.eventsReducer.eventsList
});

export default connect(
  mapStateToProps,
  {
    getUser,
    getUserRoles,
    updateUser,
    updateUserParams,
    toggleUserDeleteDialog,
    deleteUser,
    getUserEvents,
    getEvents
  }
)(withRouter(UserDetails));
