import {DateTime} from "luxon";

export class Event {
    id: number;
    name: string;
    description: string;
    eventDate: string;
    eventEndDate: DateTime;
    website: string;
    location: any;
    isActive: boolean;
    showOrder: boolean;
    printPosters: boolean;
    posterReceptionStartDate: DateTime;
    posterReceptionEndDate: DateTime;
    titleColor: string;
    authorsColor: string;
    workshopColor: string;
    posterTemplateUrl: string;
    posterTemplateFileName: string;
    eventBannerUrl: string;
    eventBannerFileName: string;
    createdAt: DateTime;
    updatedAt: DateTime;
}
