import {DateTime} from "luxon";

export class Category {
    id: number;
    order: number;
    name: string;
    description: string;
    eventId: number;
    createdAt: DateTime;
    updatedAt: DateTime;
}
