// @flow
import * as React from 'react';
import {getFileExtension, imageExtensions, powerPointExtensions} from "../../generalUtils/generalUtils";
import {PdfPreview} from "./PdfPreview";
import {BASE_URL} from "../../configs";

type Props = {
    attachmentLink: string,
    filename: string,
    width: number,
    height: number,
    containerStyle: any
};

export const AttachmentPreview = (props: Props) => {



    if(!props.filename || !props.attachmentLink)
        return (
            <div style={{padding: 20, background: "#eeeeee", minWidth: 100, textAlign: 'center'}}>
                Sin archivo
            </div>
        );

    const url = BASE_URL + props.attachmentLink;

    const extension = getFileExtension(props.filename).toLowerCase();


    if(!extension)
        return (
            <div style={props.containerStyle}>
                Error al detectar la extensión de {props.filename}
            </div>
        );

    if(imageExtensions.indexOf(extension) >= 0)
        return (
        <div style={props.containerStyle}>
            <img src={url} alt=""
                 style={{
                     width: 200,
                     objectFit: 'contain',
                 }}
            />
        </div>);

    if(extension === 'pdf')
        return (
            <div style={props.containerStyle}>
                <PdfPreview fileUrl={url} width={props.width} height={props.height}/>
            </div>);

    return (
        <div>
            <div>ARCHIVO NO SOPORTADO .{extension}</div>
            <div>ARCHIVOS SOPORTADOS</div>
            <div>IMAGENES:</div>
            {imageExtensions.map((ext) => (<div>{ext}</div>))}
            <div>PDF:</div>
            <div>.pdf</div>
            <div>POWERPOINT:</div>
            {powerPointExtensions.map((ext) => (<div>{ext}</div>))}
        </div>
    );
};
