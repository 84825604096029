// @flow
import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {countUserActions, getUserActions} from "../../../redux/modules/userActionsModule";
import UsersActionsTable from "../../../components/UserActions/UsersActionsTable";
import UserActionsActionBar from "../../../components/UserActions/UserActionsActionBar";
import {getUsers} from "../../../redux/modules/usersModule";
import {getEvents} from "../../../redux/modules/eventsModule";
import {AppUser} from "../../../model/AppUser";

type Props = {
    getUserActions: () => void,
    countUserActions: () => void,
    getUsers: () => void,
    currentUser: AppUser,
    events: Event[]
};


export const UserActions = (props: Props) => {

    useEffect( () => {
        props.getUserActions();
        props.countUserActions();
        if(props.currentUser.role !== 'admin')
            props.getEvents();
        props.getUsers();
    }, []);

        const allowed = props.currentUser.role === 'admin' || (props.currentUser.role === 'eventAdmin' && props.events && props.events.length > 0 );

        return (
            <Grid container direction={'column'} style={{
                padding: 20
            }}>
                <div style={{
                    marginBottom: 20,
                    fontSize: 20,
                    fontWeight: 200
                }}>
                    USUARIOS
                </div>
                {allowed ? <UserActionsActionBar/> : null}
                {allowed ? <UsersActionsTable/>: null}
            </Grid>
        );
};

const mapStateToProps = state => ({
    currentUser: state.authReducer.currentUser,
    events: state.eventsReducer.eventsList
});

export default connect(mapStateToProps, {getUserActions, countUserActions, getUsers, getEvents})(UserActions);
