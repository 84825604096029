// @flow
import React, {useState} from 'react';
import {Document, Page} from 'react-pdf'
import { pdfjs } from 'react-pdf';
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import printJS from 'print-js';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


type Props = {
    fileUrl: string,
    height: number,
    printable: boolean,
    presentationMode: boolean,
};
const PdfView = (props: Props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [pagesNumber, setPagesNumber] = useState(0);

    return (
        <Grid container direction={'column'} justify={'center'} style={{background: 'black'}}>
            <Document

                file={props.fileUrl}
                onLoadSuccess={({numPages}) => {
                    setPagesNumber(numPages);
                }}
            >
                <Page pageNumber={currentPage} height={props.height} className={'page'}/>
            </Document>
            <Grid container direction={'row'}
                  justify={'center'}
                  alignContent={'center'}
                  style={{
                      position: 'fixed',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: 50,
                      background: 'black',
                  }}>
                { props.presentationMode ? null : <div style={{
                    background: 'white',
                    borderRadius: 10,
                    cursor:'pointer',
                    padding: 10,
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 10
                }} onClick={() => {
                    if(currentPage > 1)
                        setCurrentPage(currentPage - 1);
                }}>
                    Anterior
                </div>}



                <div style={{
                    background: 'white',
                    borderRadius: 10,
                    cursor:'pointer',
                    padding: 10,
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 10
                }} onClick={() => {
                    props.history.goBack();
                }}>
                    Cerrar
                </div>

                { props.presentationMode ? null : <div style={{
                    background: pagesNumber > currentPage ? 'white' : '#eeeeee',
                    borderRadius: 10,
                    cursor:'pointer',
                    padding: 10,
                    marginTop: 10,
                    marginBottom: 10,
                }} onClick={() => {
                    if(pagesNumber > currentPage)
                        setCurrentPage(currentPage + 1);
                }}>
                    Siguiente
                </div> }

                {props.printable && !props.presentationMode ? <div style={{
                    background: 'white',
                    borderRadius: 10,
                    cursor:'pointer',
                    padding: 10,
                    margin: 10
                }} onClick={() => {

                    printJS(props.fileUrl);
                }}>
                    Imprimir
                </div> : null}

            </Grid>
        </Grid>
    );
};

export default withRouter(PdfView);
