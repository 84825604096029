import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import { routerMiddleware } from 'react-router-redux';
import { createHashHistory } from 'history';

const initState = {};
const reduxRouterMiddleware = routerMiddleware(createHashHistory());
const middleware = [thunk, reduxRouterMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore( rootReducer, initState, composeEnhancers(
    applyMiddleware(...middleware)));

export default store;
