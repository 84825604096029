import {AppUser} from "../../model/AppUser";
import {Api} from "../../services/api";
import {saveUserAction} from "./userActionsModule";

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_LOGIN_PARAMS = 'UPDATE_LOGIN_PARAMS';

const API_PATH = '/AppUsers';
const TOKEN_KEY = 'app_token';
const USER_KEY = 'app_user';

const initState = {
    token: '',
    email: '',
    password: '',
    currentUser: new AppUser()
};

type Action = {
    type: string,
    payload: any
};

export const updateLoginParams = ({email, password}) => (dispatch) => {
    dispatch({
        type: UPDATE_LOGIN_PARAMS,
        payload: {email, password}
    });
};

export const getLoggedUser = () => (dispatch) => {
    const token = localStorage.getItem(TOKEN_KEY);
    const user = JSON.parse(localStorage.getItem(USER_KEY));

    if(token && user) {
        Api.setToken(token);
        dispatch({
            type: UPDATE_USER,
            payload: user
        });
    } else {
        dispatch({
            type: UPDATE_USER,
            payload: null
        });
    }
    return user;
};

export const doLogin = ({email, password}) => async (dispatch) => {
    try {
        const response = await Api.instance.post(API_PATH + '/login?include=user', {email, password});
        if(response.data && response.data.user && response.data.user.id)
            await saveUserAction(response.data.user);

        dispatch({
            type: LOGIN,
            payload: response.data
        });
    } catch (e) {
        console.log(e);
    }
};

export const logoutAction = () => async (dispatch) => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    dispatch({
        type: LOGOUT,
        payload: {}
    });
};

export const authReducer = (state = initState, action: Action) => {
    switch (action.type) {
        case LOGIN:
            if(action.payload && action.payload.id) {
                Api.setToken(action.payload.id);
                localStorage.setItem(TOKEN_KEY, action.payload.id);
                localStorage.setItem(USER_KEY, JSON.stringify(action.payload.user));
                return {
                    ...state,
                    token: action.payload.id,
                    currentUser: action.payload.user
                };
            } else {
                return {
                    ...state,

                };
            }

        case LOGOUT:
            return {
                ...state,
                currentUser: null,
                token: null
            };
        case UPDATE_USER:
            return {
                ...state,
                currentUser: action.payload
            };
        case UPDATE_LOGIN_PARAMS:
            return {
                ...state,
                email: action.payload.email,
                password: action.payload.password,
            };
        default:
            return state;
    }
};
