import {Api} from "../../services/api";
import {BASE_URL} from "../../configs";
import {goBack} from "react-router-redux";
import {Poster} from "../../model/Poster";

import {getFileExtension, removeFileExtension, sanitizeFilename} from "../../generalUtils/generalUtils";
import {DocConversionApi} from "../../services/docConversionApi";

export const GET_POSTERS = 'GET_POSTERS';
export const FILTER_POSTERS = 'FILTER_POSTERS';
export const GET_POSTERS_FROM_EVENT = 'GET_POSTERS_FROM_EVENT';
export const GET_POSTERS_FROM_CATEGORY = 'GET_POSTERS_FROM_CATEGORY';
export const GET_POSTER = 'GET_POSTER';

export const UPDATE_POSTER = 'UPDATE_POSTER';
export const UPDATE_POSTER_QUERY = 'UPDATE_POSTER_QUERY';
export const UPDATE_POSTER_PARAMS = 'UPDATE_POSTER_PARAMS';
export const UPDATE_POSTER_FILE = 'UPDATE_POSTER_FILE';
export const DELETE_POSTER = 'DELETE_POSTER';
export const TOGGLE_POSTER_DELETE_CONFIRMATION = 'TOGGLE_POSTER_DELETE_CONFIRMATION';
export const CHANGE_LOADING_STATE = 'CHANGE_LOADING_STATE';

const API_PATH = '/Posters';
const FILES_PATH = '/Attachments';
const CONTAINER_PATH = '/posterData';

const initState = {
    posterListQuery: '',
    posterList: [],
    posterPresentationPosterIndex: 0,
    loading: false,
    showDeleteConfirmation: false,
    selectedPoster: new Poster(),
    selectedPosterFile: null,
    posterFromEventList: [],
    posterFromCategoryList: [],
    filteredPosters: []
};

type Action = {
    type: string,
    payload: any
};

export const togglePosterDeleteDialog = (open) => (dispatch) => {
    dispatch({
        type: TOGGLE_POSTER_DELETE_CONFIRMATION,
        payload: open
    });
};

export const updatePosterParams = (poster) => (dispatch) => {
    dispatch({
        type: UPDATE_POSTER_PARAMS,
        payload: poster
    });
};

export const updatePosterFile = (file) => (dispatch) => {
    dispatch({
        type: UPDATE_POSTER_FILE,
        payload: file
    });
};

export const deletePoster = () => async (dispatch, getState) => {
    const {postersReducer} = getState();
    dispatch({
        type: CHANGE_LOADING_STATE,
        payload: true
    });
    try {
        const currentPoster = postersReducer.selectedPoster;
        if(currentPoster.fileUrl && currentPoster.fileName)
            await Api.instance.delete(FILES_PATH + CONTAINER_PATH + '/files/' + currentPoster.fileName);
        await Api.instance.delete(API_PATH + '/' + currentPoster.id);
        dispatch({
            type: TOGGLE_POSTER_DELETE_CONFIRMATION,
            payload: false
        });
        dispatch({
            type: UPDATE_POSTER_PARAMS,
            payload: {}
        });
        dispatch(goBack());
    } catch (e) {
        console.log(e);
    }
    dispatch({
        type: CHANGE_LOADING_STATE,
        payload: false
    });
};

export const uploadPosterFile = () => async (dispatch, getState) => {
    const {postersReducer} = getState();
    const file = postersReducer.selectedPosterFile;
    const poster = postersReducer.selectedPoster;

    if(poster.fileName)
        await Api.instance.delete(BASE_URL + FILES_PATH + CONTAINER_PATH + `/files/${poster.fileName}`);

    if(getFileExtension(file.name) === 'pptx') {
        const convertedFile = await DocConversionApi.convertDocumentPptxToPdf(file);
        await uploadFile(convertedFile, removeFileExtension(file.name)+'.pdf', poster, dispatch);
    } else {
        await uploadFile(file, file.name, poster, dispatch);
    }
};

const uploadFile = async(file, fileName, poster, dispatch) => {
    // FILE UPLOAD LOGIC
    const data = new FormData();
    data.append('file', file, sanitizeFilename(fileName));
    try {
        const uploadResponse = await Api.instance.post(FILES_PATH + CONTAINER_PATH + '/upload', data, {});
        dispatch({
            type: UPDATE_POSTER_FILE,
            payload: null
        });
        if(uploadResponse.data.result && uploadResponse.data.result.files && uploadResponse.data.result.files.file) {
            const file = uploadResponse.data.result.files.file[0];
            const newPoster: Poster = {...poster, fileName: file.name, fileUrl: FILES_PATH + `/${file.container}/download/${file.name}`};
            try {
                let response;
                response = await Api.instance.post(API_PATH + '/replaceOrCreate', newPoster);
                dispatch({
                    type: UPDATE_POSTER,
                    payload: response.data
                });
            } catch (e) {
                console.log(e);
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const updatePoster = () => async (dispatch, getState) => {
    const {postersReducer} = getState();
    dispatch({
        type: CHANGE_LOADING_STATE,
        payload: true
    });
    try {
        let response;
        response = await Api.instance.post(API_PATH + '/replaceOrCreate', postersReducer.selectedPoster);

        dispatch({
            type: UPDATE_POSTER,
            payload: response.data
        });
    } catch (e) {
        console.log(e);
    }
    dispatch({
        type: CHANGE_LOADING_STATE,
        payload: false
    });
};

export const updatePosterQuery = (query) => (dispatch) => {
    dispatch({
        type: UPDATE_POSTER_QUERY,
        payload: query
    });
};

export const getPosters = (query) => async (dispatch) => {
    try {
        const filter = query ? {where: {name: {ilike: `${query}%25`}}}: null;
        const queryUrl = query ? `?filter=${JSON.stringify(filter)}` : '';
        const response = await Api.instance.get(API_PATH + queryUrl);
        dispatch({
            type: GET_POSTERS,
            payload: response.data
        });
    } catch (e) {
        console.log(e);
    }
};

export const fetchPostersFromEvent = async eventId => {
    const filter = {where: {eventId}};
    const queryUrl = `?filter=${JSON.stringify(filter)}`;
    return await Api.instance.get(API_PATH + queryUrl);
};

export const getPostersFromEvent = eventId => async dispatch => {
    try {
        const response = await fetchPostersFromEvent(eventId);
        dispatch({
            type: GET_POSTERS_FROM_EVENT,
            payload: response.data
        });
    } catch (e) {
        console.log(e);
    }
};

export const fetchPostersFromCategory = async categoryId => {
    const filter = {where: {categoryId}};
    const queryUrl = `?filter=${JSON.stringify(filter)}`;
    return await Api.instance.get(API_PATH + queryUrl);
};


export const getPostersFromCategory = (categoryId) => async (dispatch) => {
    try {
        const response = await fetchPostersFromCategory(categoryId);
        dispatch({
            type: GET_POSTERS_FROM_CATEGORY,
            payload: response.data
        });
    } catch (e) {
        console.log(e);
    }
};

export const filterPosters = (query: string, list: Poster[]) => dispatch => {

    let filtered = [];
    console.log(query);

    if(!query || !list)
        filtered = list;
    else
        list.forEach((p) => {
            if(p.name && p.name.toLowerCase().includes(query.toLowerCase())) {
                filtered.push(p);
            }
            else if(p.code && p.code.toLowerCase().includes(query.toLowerCase())) {
                filtered.push(p);
            }
            else if(p.authors && p.authors.toLowerCase().includes(query.toLowerCase())) {
                filtered.push(p);
            }
        });
    console.log(filtered);
    return dispatch({
        type: FILTER_POSTERS,
        payload: filtered ? [...filtered] : []
    });
};

export const fetchPoster = async (id) => {
    return await Api.instance.get(API_PATH + `/${id}`);
};

export const getPoster = (id) => async (dispatch) => {
    if(id === 'new') {
        return dispatch({
            type: GET_POSTER,
            payload: {}
        });
    }

    try {
        const response = await fetchPoster(id);
        return dispatch({
            type: GET_POSTER,
            payload: {...response.data}
        });
    } catch (e) {
        console.log(e);
    }
};


export const postersReducer = (state = initState, action: Action) => {
    switch (action.type) {
        case GET_POSTERS:
            if(action.payload) {
                return {
                    ...state,
                    posterList: action.payload
                };
            } else {
                return {
                    ...state,
                };
            }
        case GET_POSTER:
            return {
                ...state,
                selectedPoster: action.payload
            };
        case FILTER_POSTERS:
            return {
                ...state,
                filteredPosters: action.payload
            };
        case GET_POSTERS_FROM_EVENT:
            return {
                ...state,
                posterFromEventList: action.payload
            };
        case GET_POSTERS_FROM_CATEGORY:
            return {
                ...state,
                posterFromCategoryList: action.payload
            };
        case CHANGE_LOADING_STATE:
            return {
                ...state,
                loading: action.payload
            };
        case UPDATE_POSTER:
            return {
                ...state,
                selectedPoster: action.payload
            };
        case UPDATE_POSTER_PARAMS:
            return {
                ...state,
                selectedPoster: {...action.payload}
            };
        case UPDATE_POSTER_FILE:
            return {
                ...state,
                selectedPosterFile: action.payload
            };
        case UPDATE_POSTER_QUERY:
            return {
                ...state,
                posterListQuery: action.payload
            };
        case TOGGLE_POSTER_DELETE_CONFIRMATION:
            return {
                ...state,
                showDeleteConfirmation: action.payload
            };
        case DELETE_POSTER:
            return {
                ...state,
            };
        default:
            return state;
    }
};
