// @flow
import React, {useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import { getUserActions, updateActionsQuery} from "../../redux/modules/userActionsModule";
import UsersSelector from "../users/UsersSelector";
import {AppUser} from "../../model/AppUser";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {DateTime} from "luxon";
import EventsSelector from "../events/EventsSelector";
import {getUsers} from "../../redux/modules/usersModule";

type Props = {
    userId: string,
    history: any,
    from: DateTime,
    to: DateTime,
    users: AppUser[],
    events: Event[],
    getUserActions: () => void,
    getUsers: (eventId: string) => void,
    updateActionsQuery: (query: string) => void,
    currentUser: AppUser,
    eventId: string,
};
const UserActionsActionBar = (props: Props) => {

    useEffect(() => {
        if(props.currentUser.role !== 'admin' && props.events && props.events[0].id) {
            props.getUsers(null, props.events[0].id);
        }
    },[props.events, props.currentUser]);

  return (
    <Paper style={{ marginBottom: 10 }}>
      <Grid container
            direction={"row"}
            justify={"flex-end"}
            alignItems={'center'}
            style={{
                padding: 20
            }}
      >
          <KeyboardDatePicker
              style={{marginLeft: 10, marginRight: 10}}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id="start-date"
              label="Desde"
              value={props.from}
              onChange={date => {
                  const currentQuery = {userId: props.userId,from: date, to: props.to};
                  props.updateActionsQuery(currentQuery);
                  props.getUserActions();
              }}
              KeyboardButtonProps={{
                  "aria-label": "change date"
              }}
          />

          <KeyboardDatePicker
              style={{marginLeft: 10, marginRight: 10}}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id="end-date"
              label="Hasta"
              value={props.to}
              onChange={date => {
                  const currentQuery = {userId: props.userId,from: props.from, to: date};
                  props.updateActionsQuery(currentQuery);
                  props.getUserActions();
              }}
              KeyboardButtonProps={{
                  "aria-label": "change date"
              }}
          />


          {props.currentUser.role !== 'admin' ? <div style={{marginRight: 10}}>Eventos</div>: null}
          {props.currentUser.role !== 'admin' ? <EventsSelector events={props.events} currentEventId={props.eventId || ''} onEventSelected={(eventName) => {
              props.events.forEach((event) => {
                  if(event.name.toLowerCase() === eventName.toLowerCase()) {
                      props.getUsers(null, event.id);
                  }
              });
          }}/> : null}
          <div style={{marginRight: 10}}>Usuario</div>
          <UsersSelector users={props.users} currentUserId={props.query || ''} onUserSelected={(userEmail) => {
              props.users.forEach((user) => {
                  if(user.email.toLowerCase() === userEmail.toLowerCase()) {
                      const currentQuery = {userId: user.id,from: props.from, to: props.to};
                      props.updateActionsQuery(currentQuery);
                      props.getUserActions();
                  }
              });
          }}/>
          <Button style={{marginLeft: 5}} onClick={() => {
              props.updateActionsQuery({});
              props.getUserActions();
          }}>
              <Close/>
          </Button>
      </Grid>
    </Paper>
  );
};
const mapStateToProps = (state) => ({
    from: state.userActionsReducer.from,
    to: state.userActionsReducer.to,
    userId: state.userActionsReducer.userId,
    eventId: state.usersReducer.eventId,
    users: state.usersReducer.usersList,
    events: state.eventsReducer.eventsList,
    currentUser: state.authReducer.currentUser,
});
export default connect(mapStateToProps, {updateActionsQuery, getUserActions, getUsers})(UserActionsActionBar);
